import React, { useEffect } from 'react';
import { Dialog, DialogContent, DialogTitle, Button, TextField } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { apiCall } from '../../utils/serverFunctions';
import { ChargeName } from 'models/index.model';
import { generateMenuItemsFromArray } from 'utils/generateMenuItemsFromArray';
import GeneralDialogueActions from 'components/GeneralDialogueActions';

interface ChargeDialogProps {
  open: boolean;
  charge: ChargeName | null;
  initialChargeName?: string;
  onClose: (updatedCharge: ChargeName | null) => void;
}

const ChargeDialog: React.FC<ChargeDialogProps> = ({ open, charge, initialChargeName, onClose }) => {
  const { control, handleSubmit, setValue, watch, register } = useForm<ChargeName>({
    defaultValues: {
      chargeName: charge?.chargeName || initialChargeName || '',
      chargeType: charge?.chargeType || '',
      defaultBase: charge?.defaultBase || '',
    },
  });

  const currentChargeName = watch('chargeName');

  useEffect(() => {
    if (!charge && initialChargeName && currentChargeName !== initialChargeName) {
      setValue('chargeName', initialChargeName);
    }
  }, [charge, initialChargeName, setValue, currentChargeName]);

  const onSubmit = async (data: ChargeName) => {
    try {
      const endpoint = charge ? `/api/v1/charges/${charge._id}` : '/api/v1/charges';
      const method = charge ? 'PATCH' : 'POST';
      const response = await apiCall<ChargeName>(endpoint, { method, data });
      onClose(response?.data || null);
    } catch (error) {
      console.error('Error saving charge:', error);
    }
  };

  return (
    <Dialog open={open} onClose={() => onClose(null)}>
      <form>
        <DialogTitle>{charge ? 'Edit Charge' : 'Create Charge'}</DialogTitle>
        <DialogContent>
          <TextField
            {...register('chargeName', { required: 'Charge Name is required' })}
            margin='dense'
            label='Charge Name'
            fullWidth
            required
            autoComplete='off'
          />
          <Controller
            name='chargeType'
            control={control}
            rules={{ required: 'Charge Type is required' }}
            render={({ field }) => (
              <TextField {...field} select margin='dense' label='Charge Type' fullWidth required autoComplete='off'>
                {generateMenuItemsFromArray(['Sales', 'Purchase'])}
              </TextField>
            )}
          />
          <Controller
            name='defaultBase'
            control={control}
            rules={{ required: 'Default Base is required' }}
            render={({ field }) => (
              <TextField {...field} select margin='dense' label='Default Base' fullWidth required autoComplete='off'>
                {generateMenuItemsFromArray(['CN', 'BL'])}
              </TextField>
            )}
          />
        </DialogContent>
        <GeneralDialogueActions onClick={handleSubmit(onSubmit)} handleClose={() => onClose(null)} submitText='Save' />
      </form>
    </Dialog>
  );
};

export default ChargeDialog;
