import {
  AlertColor,
  Autocomplete,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
  useTheme,
} from '@mui/material';
import AlertSnackbar from 'components/AlertSnackbar';
import GeneralDialogueActions from 'components/GeneralDialogueActions';
import { BankData } from 'dataAssets/constants';
import { BaseDialogueProps, IBankAccounts } from 'models/index.model';
import React, { useEffect, useState } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { setSnackbarSettings } from 'state';
import { apiCall } from 'utils/serverFunctions';

interface CreateBankAccountsrops extends BaseDialogueProps {
  entityToUpdate?: IBankAccounts;
  useFormReference: UseFormReturn<IBankAccounts>;
  defaultValues: any;
}

const CreateBankAccounts: React.FC<CreateBankAccountsrops> = ({
  handleClose,
  open,
  entityToUpdate,
  useFormReference,
  defaultValues,
}) => {
  const theme = useTheme();
  const isEditing = !!entityToUpdate?._id;

  const {
    register,
    control,
    handleSubmit,
    setValue,
    watch,
    reset,
    getValues,
    formState: { errors },
  } = useFormReference;

  useEffect(() => {
    if (entityToUpdate?._id) {
      reset(entityToUpdate);
    } else {
      reset(defaultValues);
    }
  }, [reset, entityToUpdate]);

  const countryList = BankData?.countries?.map(bd => bd.name);
  const [banks, setBanks] = useState<string[]>([]);
  const selectedCountry = watch('branch');
  const dispatch = useDispatch();
  useEffect(() => {
    const selectedBanks = BankData.countries.find(country => country.name === selectedCountry)?.banks || [];
    setBanks(selectedBanks);
  }, [selectedCountry]);

  const [snackbarOpen, setSnackbarOpen] = useState<{
    status: boolean;
    severity: AlertColor;
    message: string;
  }>({
    status: false,
    severity: 'success',
    message: '',
  });

  const onSubmit = async (data: IBankAccounts) => {
    if (isEditing) {
      await apiCall(`/api/v1/bankAccounts/${entityToUpdate._id}`, {
        method: 'PATCH',
        data,
      });
    } else {
      await apiCall(`/api/v1/bankAccounts/`, {
        method: 'POST',
        data,
      });
    }
    reset(defaultValues);
    dispatch(
      setSnackbarSettings({
        snackbarOpen: true,
        snackbarSeverity: 'success',
        message: isEditing ? `Bank Account updated` : `Bank Account Created`,
      }),
    );
    handleClose();
  };

  return (
    <Box>
      <Dialog open={open} onClose={() => handleClose()} fullWidth maxWidth='md'>
        <DialogTitle
          sx={{
            fontSize: '1.6rem',
          }}
        >
          {isEditing ? `Edit Bank Account` : `Create Bank Account`}
        </DialogTitle>
        <DialogContent>
          <form>
            <Box display={'grid'} gridTemplateColumns={'1fr'} gap={'1rem'} sx={{ paddingTop: '10px' }}>
              <Controller
                control={control}
                name={'branch'}
                rules={{ required: `branch is required` }}
                render={({ field: { value, onChange } }) => (
                  <Autocomplete
                    value={value ? value : null}
                    onChange={(_, value) => {
                      onChange(value);
                    }}
                    options={countryList}
                    isOptionEqualToValue={(option, value) => option === value}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label={`Branch*`}
                        error={!!errors.branch}
                        helperText={errors.branch?.message}
                      />
                    )}
                  />
                )}
              />
              <Controller
                control={control}
                name={'bank'}
                rules={{ required: `Bank is required` }}
                render={({ field: { value, onChange } }) => (
                  <Autocomplete
                    value={value ? value : null}
                    onChange={(_, value) => {
                      onChange(value);
                    }}
                    options={banks ?? []}
                    isOptionEqualToValue={(option, value) => option === value}
                    renderInput={params => (
                      <TextField {...params} label={`Bank*`} error={!!errors.bank} helperText={errors.bank?.message} />
                    )}
                  />
                )}
              />
              <Controller
                control={control}
                name={`address`}
                rules={{ required: 'address is Required' }}
                render={({ field: { value, onChange } }) => (
                  <TextField
                    autoComplete='off'
                    type='text'
                    label='Address *'
                    value={value || ''}
                    onChange={onChange}
                    error={!!errors.address}
                    helperText={errors.address?.message}
                  />
                )}
              />
              <Controller
                control={control}
                name={`accountNumber`}
                rules={{ required: 'accountNumber is Required' }}
                render={({ field: { value, onChange } }) => (
                  <TextField
                    autoComplete='off'
                    type='text'
                    label='A/C No. *'
                    value={value || ''}
                    onChange={onChange}
                    error={!!errors.accountNumber}
                    helperText={errors.accountNumber?.message}
                  />
                )}
              />
              <Controller
                control={control}
                name={`currency`}
                rules={{ required: 'Currency is Required' }}
                render={({ field: { value, onChange } }) => (
                  <TextField
                    autoComplete='off'
                    type='text'
                    label='Currency *'
                    value={value || ''}
                    onChange={onChange}
                    error={!!errors.currency}
                    helperText={errors.currency?.message}
                  />
                )}
              />
            </Box>
            <Divider sx={{ mt: '1rem' }}>Additional Details</Divider>
            <Box display={'grid'} gridTemplateColumns={'1fr'} gap={'1rem'} sx={{ paddingTop: '10px' }}>
              <Controller
                control={control}
                name={`iban`}
                render={({ field: { value, onChange } }) => (
                  <TextField autoComplete='off' type='text' label='IBAN' value={value || ''} onChange={onChange} />
                )}
              />
              <Controller
                control={control}
                name={`sortCode`}
                render={({ field: { value, onChange } }) => (
                  <TextField autoComplete='off' type='text' label='Sort Code' value={value || ''} onChange={onChange} />
                )}
              />
              <Controller
                control={control}
                name={`swiftBic`}
                render={({ field: { value, onChange } }) => (
                  <TextField autoComplete='off' type='text' label='Swift BIC' value={value || ''} onChange={onChange} />
                )}
              />
            </Box>
          </form>
        </DialogContent>
        <GeneralDialogueActions
          onClick={handleSubmit(onSubmit)}
          handleClose={handleClose}
          submitText={isEditing ? 'Edit' : 'Create'}
        />
        <AlertSnackbar
          open={snackbarOpen.status}
          handleClose={() => setSnackbarOpen({ status: false, severity: 'success', message: '' })}
          severity={snackbarOpen.severity}
          message={snackbarOpen.message}
        />
      </Dialog>
    </Box>
  );
};

export default CreateBankAccounts;
