import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogContent,
  Box,
  Tooltip,
  DialogTitle,
  IconButton,
} from '@mui/material';
import { ContainerTypeData } from 'models/analytics';
import { BaseDialogueProps } from 'models/index.model';
import GeneralDialogueActions from 'components/GeneralDialogueActions';
import { getAllMonthNames, getCurrentMonth, handleExcelDownload, truncateName } from 'utils/utils';
import { DownloadOutlined } from '@mui/icons-material';

interface ContainerTypeMonthlyReportTableProps extends BaseDialogueProps {
  data: ContainerTypeData[];
  bookingType: string;
  selectedYear: number;
  selectedMonth: number | null;
}

const ContainerTypeMonthlyReportTable: React.FC<ContainerTypeMonthlyReportTableProps> = ({
  data,
  bookingType,
  handleClose,
  open,
  selectedYear,
  selectedMonth,
}) => {
  const currentMonth = getCurrentMonth(selectedYear, selectedMonth);
  const allMonths = getAllMonthNames(currentMonth);
  const excelFileName = selectedMonth
    ? `Container-Type-Monthly-${bookingType}-Report_${allMonths[selectedMonth - 1]}-${selectedYear.toString()}`
    : `Container-Type-Monthly-${bookingType}-Report_${selectedYear.toString()}`;

  const transformedData = data.map(item => {
    const volumeColumns: { [key: string]: number } = {};
    item.monthlyContainerBookings.forEach((booking, index) => {
      volumeColumns[`${allMonths[index]} (Volume)`] = booking.volume;
    });

    return {
      'Container Type': item.containerType,
      ...volumeColumns,
      'Total Volume': item.totalVolume,
    };
  });

  const totalVolumes = allMonths.map(month => {
    return data.reduce((total, containerType) => {
      const monthData = containerType.monthlyContainerBookings.find(m => m.month === month);
      return total + (monthData?.volume || 0);
    }, 0);
  });

  const transformedTotalVolumes: { [key: string]: number } = {};
  totalVolumes.forEach((volume, index) => {
    transformedTotalVolumes[`${allMonths[index]} (Volume)`] = volume;
  });

  const transformedDataWithTotalVolumes = [
    ...transformedData,
    {
      'Container Type': 'YTD',
      ...transformedTotalVolumes,
      'Total Volume': data.reduce((total, containerType) => total + containerType.totalVolume, 0),
    },
  ];

  return (
    <Box>
      <Dialog open={open} onClose={() => handleClose()} fullWidth fullScreen maxWidth='xl'>
        <DialogTitle>
          <Box display={'flex'} alignItems={'center'} gap={1}>
            {bookingType} Monthly Container Type Report{' '}
            <Tooltip title='Download as Excel' placement='top'>
              <IconButton onClick={() => handleExcelDownload(transformedDataWithTotalVolumes, excelFileName)}>
                <DownloadOutlined />
              </IconButton>
            </Tooltip>
          </Box>
        </DialogTitle>
        <DialogContent>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align='center' sx={{ borderRight: '1px solid #515151' }}>
                    Container Type
                  </TableCell>
                  {allMonths.map(month => (
                    <React.Fragment key={month}>
                      <TableCell align='center' sx={{ borderRight: '1px solid #515151' }}>
                        {month} (Volume)
                      </TableCell>
                    </React.Fragment>
                  ))}
                  <TableCell align='center'>Total Volume</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((containerType, index) => (
                  <TableRow key={index} sx={{ backgroundColor: '#292929' }}>
                    <TableCell align='left' sx={{ borderRight: '1px solid #515151' }}>
                      <Tooltip title={containerType.containerType} placement='top'>
                        <span>{truncateName(containerType.containerType)}</span>
                      </Tooltip>
                    </TableCell>
                    {allMonths.map(month => {
                      const monthData = containerType.monthlyContainerBookings.find(m => m.month === month);
                      return (
                        <React.Fragment key={`${month}-${containerType.containerId}`}>
                          <TableCell align='center' sx={{ borderRight: '1px solid #515151' }}>
                            {monthData?.volume || 0}
                          </TableCell>
                        </React.Fragment>
                      );
                    })}
                    <TableCell align='center'>{containerType.totalVolume}</TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell sx={{ borderRight: '1px solid #515151' }}>YTD</TableCell>
                  {totalVolumes.map((totalVolume, index) => (
                    <React.Fragment key={index}>
                      <TableCell align='center' sx={{ borderRight: '1px solid #515151' }}>
                        {totalVolume}
                      </TableCell>
                    </React.Fragment>
                  ))}
                  <TableCell align='center'>
                    {data.reduce((total, containerType) => total + containerType.totalVolume, 0)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <GeneralDialogueActions noSubmission handleClose={handleClose} />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default ContainerTypeMonthlyReportTable;
