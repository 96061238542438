import React from 'react';
import { Box, Typography } from '@mui/material';

interface AnalyticsCardHeaderProps {
  title: string;
  subtitle?: string;
}

const AnalyticsCardHeader: React.FC<AnalyticsCardHeaderProps> = ({ title, subtitle }) => {
  return (
    <Box margin={'0.75rem 1rem 0.5rem 1rem'}>
      <Typography mb='-0.1rem' fontSize={'11px'}>
        {title}
      </Typography>
      <Typography variant='caption'>{subtitle}</Typography>
    </Box>
  );
};

export default AnalyticsCardHeader;
