import React from 'react';
import { Box, useTheme } from '@mui/material';
import { Outlet } from 'react-router-dom';
import Sidebar from '../../components/Sidebar';

const Layout = () => {
  const theme = useTheme();

  return (
    <div>
      <Box display={'flex'} width='100%' height='100vh'>
        <Sidebar drawerWidth='16rem' />
        <Box
          flexGrow={1}
          sx={{
            marginLeft: '16rem',
            backgroundColor: '#1F1F21',
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
              margin: '1rem 1rem 1rem 0',
              padding: '1rem',
              backgroundColor: '#161618',
              borderRadius: '1rem',
              overflowY: 'auto',
              '&::-webkit-scrollbar': {
                display: 'none',
              },
            }}
          >
            <Outlet />
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default Layout;
