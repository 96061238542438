import React from 'react';
import { Box, Dialog, DialogContent, DialogTitle } from '@mui/material';
import { PDFViewer } from '@react-pdf/renderer';
import HblDocument from 'components/HblDocument/HblDocument';
import { BaseDialogueProps } from 'models/index.model';
import GeneralDialogueActions from 'components/GeneralDialogueActions';
import { Hbl } from 'models/hbl.model';

export interface HblDocumentViewerProps extends BaseDialogueProps {
  hbl: Hbl | undefined;
  copyVersion?: Boolean;
}

const HblDocViewer: React.FC<HblDocumentViewerProps> = ({ handleClose, open, hbl, copyVersion }) => {
  return (
    <Box>
      <Dialog open={open} onClose={() => handleClose()} fullScreen>
        <DialogTitle
          sx={{
            fontSize: '1.6rem',
          }}
        >
          {`HBL Document: ${hbl?.blNumber}`}
        </DialogTitle>
        <DialogContent>
          <PDFViewer style={{ width: '100%', height: '100%' }}>
            <HblDocument hbl={hbl} copyVersion={copyVersion} />
          </PDFViewer>
        </DialogContent>
        <GeneralDialogueActions handleClose={handleClose} noSubmission />
      </Dialog>
    </Box>
  );
};

export default HblDocViewer;
