'use client';

import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  useTheme,
  Stack,
  Select,
  FormControl,
  InputLabel,
  SelectChangeEvent,
  useMediaQuery,
  Grid,
} from '@mui/material';
import { InfoOutlined, KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material';
import AnalyticsCard from 'components/AnalyticsCard';
import AnalyticsCardHeader from 'components/AnalyticsCardHeader';
import CustomPieChart from 'components/charts/CustomPieChart';
import CustomLineChart from 'components/charts/LineChart';
import CustomerMonthlyStats from 'components/charts/CustomerMonthlyStats';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Gauge, gaugeClasses } from '@mui/x-charts';
import {
  useGetCommodityVolumesProftsQuery,
  useGetContainerTypeDataQuery,
  useGetCustomerVolumesProfitsByMonthQuery,
  useGetMerchantHauleStatsQuery,
  useGetMonthlyCarrierVolumesQuery,
  useGetMonthlyCommodityVolumeAndProfitQuery,
  useGetMonthlyGrossProfitsQuery,
  useGetMonthlyHaulerStatsQuery,
  useGetMonthlyPortCombinationVolumesQuery,
  useGetMonthlyPortVolumesQuery,
  useGetMonthlyVolumesQuery,
  useGetPortCombinationVolumesQuery,
  useGetPortVolumesQuery,
  useGetTotalCarrierVolumesQuery,
  useGetTransportTypeVolumesQuery,
  useGetMonthlyVolumesForKpiQuery,
  useGetMonthlyProfitsForKpiQuery,
  useGetOldestBookingYearQuery,
  useGetCummulativeVolumesForKpiQuery,
  useGetCummulativeProfitsForKpiQuery,
} from 'state/api';
import {
  Area,
  AreaChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  BarChart,
  CartesianGrid,
  Bar,
  LabelList,
  PieChart,
  Pie,
} from 'recharts';
import { CustomerVolumeProfitByMonth } from 'models/analytics';
import CustomerVolumeAndProfitMonthlyReportTable from 'components/MonthlyReportTable/CustomerVolumeAndProfitMonthlyReportTable';
import ContainerTypeMonthlyReportTable from 'components/MonthlyReportTable/ContainerTypeMonthlyReportTable';
import MonthlyHaulerStatsTable from 'components/MonthlyReportTable/MonthlyHaulerStatsTable';
import MonthlyCommodityVolumeAndProfitTable from 'components/MonthlyReportTable/MonthlyCommodityVolumeAndProfitTable';
import MonthlyPortVolumeTable from 'components/MonthlyReportTable/MonthlyPortVolumeTable';
import MonthlyPortCombinationVolumesTable from 'components/MonthlyReportTable/MonthlyPortCombinationVolumesTable';
import MonthlyCarrierVolumeTable from 'components/MonthlyReportTable/MonthlyCarrierVolumeTable';
// import { APIProvider, Map } from "@vis.gl/react-google-maps";
import dayjs from 'dayjs';
import Header from 'components/Header';
import { formatpercentageChange, getMonthNamesFromYear } from 'utils/utils';
import RenderChangeBox from 'components/RenderChangeBox';

const gridTemplate = {
  xs: `
    "a"
    "b"
    "c"
    "d"
    "e"
    "f"
    "g"
    "h"
    "i"
  `,
  sm: `
    "a b"
    "c d"
    "e f"
    "g h"
    "i i"
  `,
  md: `
    "a b c"
    "a b c"
    "a b c"
    "a b f"
    "d e f"
    "d e f"
    "d h i"
    "g h i"
    "g h i"
    "g h i"
  `,
};

const AnalyticsDashboard = () => {
  const theme = useTheme();
  const currentYear = dayjs().year();
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));
  const isSm = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isMd = useMediaQuery(theme.breakpoints.up('md'));
  const gaugeSize = isMd ? 150 : 100;
  const gaugeValueFontSize = isXs ? 10 : isSm ? 20 : 30;

  const [customerVolumeProfitType, setCustomerVolumeProfitType] = useState('Export');
  const [customerProfitType, setCustomerProfitType] = useState('Expected');
  const [commodityBookingType, setCommodityBookingType] = useState('Export');
  const [loadingsBookingType, setLoadingsBookingType] = useState('Export');
  const [portCombinationBookingType, setPortCombinationBookingType] = useState('Export');
  const [containerTypeDataBookingType, setContainerTypeDataBookingType] = useState('Export');
  const [customerVolumeAndProfitanchorEl, setCustomerVolumeAndProfitAnchorEl] = useState<null | HTMLElement>(null);
  const [containerTypeAnchorEl, setContainerTypeAnchorEl] = useState<null | HTMLElement>(null);
  const [customerOpenDialog, setCustomerOpenDialog] = useState(false);
  const [customerVolumeAndProfitMonthlyReportDialog, setCustomerVolumeAndProfitMonthlyReportDialog] = useState(false);
  const [containerTypeMonthlyReportDialog, setContainerTypeMonthlyReportDialog] = useState(false);
  const [selectedCustomerMonthlyData, setSelectedCustomerMonthlyData] = useState({} as CustomerVolumeProfitByMonth);
  const [secondCardDisplay, setSecondCardDisplay] = useState('TotalVolumes');
  const [secondCardAnchorEl, setSecondCardAnchorEl] = useState<null | HTMLElement>(null);
  const [monthlyHaulerStatsBookingType, setMonthlyHaulerStatsBookingType] = useState('Export');
  const [monthlyHaulerStatsMonthlyReportDialog, setHaulerStatsMonthlyReportDialog] = useState(false);
  const [transportAndHaulerVolumesAnchorEl, setTransportAndHaulerVolumesAnchorEl] = useState<null | HTMLElement>(null);
  const [commodityVoulmesAndGPAnchorEl, setCommodityVolumesAndGPAnchorEl] = useState<null | HTMLElement>(null);
  const [portVolumesAnchorEl, setPortVolumesAnchorEl] = useState<null | HTMLElement>(null);
  const [commonTradeAnchorEl, setCommonTradeAnchorEl] = useState<null | HTMLElement>(null);
  const [monthlyCommodityVolumeAndProfitBookingType, setMonthlyCommodityVolumeAndProfitBookingType] =
    useState('Export');
  const [commodityVolumeAndProfitMonthlyReportDialog, setCommodityVolumeAndProfitMonthlyReportDialog] = useState(false);
  const [portVolumesMonthlyReportDialog, setPortVolumesMonthlyReportDialog] = useState(false);
  const [monthlyPortCombinationBookingType, setMonthlyPortCombinationBookingType] = useState('Export');
  const [commonMonthlyPortCombinationReportDialog, setCommonMonthlyPortCombinationReportDialog] = useState(false);
  const [totalCarrierVolumesAnchorEl, setTotalCarrierVolumesAnchorEl] = useState<null | HTMLElement>(null);
  const [totalCarrierVolumesBookingType, setTotalCarrierVolumesBookingType] = useState('Export');
  const [totalCarrierVolumesMonthlyReportDialog, setTotalCarrierVolumesMonthlyReportDialog] = useState(false);
  const [kpiDisplay, setKpiDisplay] = useState('Volume');
  const [kpiDataAnchorEl, setKpiDataAnchorEl] = useState<null | HTMLElement>(null);
  const [kpiBookingType, setKpiBookingType] = useState('Export');
  const [startYear, setStartYear] = useState<number>(currentYear);
  const [selectedYear, setSelectedYear] = useState<number>(currentYear);
  const [formattedSelectedYear, setFormattedSelectedYear] = useState<string>(`${currentYear}-12-28`);
  const [kpiProfitType, setKpiProfitType] = useState<string>('Expected');
  const [selectedMonth, setSelectedMonth] = useState<number | null>(null);
  const [formattedSelectedMonth, setFormattedSelectedMonth] = useState<string>(`${selectedYear}--28`);
  const [monthlyGrossProfitsProfitType, setMonthlyGrossProfitsProfitType] = useState<string>('Expected');
  const [commodityVolumeAndProfitType, setCommodityVolumeAndProfitType] = useState<string>('Expected');

  const customerVolumeAndProfitMenuOpen = Boolean(customerVolumeAndProfitanchorEl);
  const containerTypeMenuOpen = Boolean(containerTypeAnchorEl);
  const secondCardMenuOpen = Boolean(secondCardAnchorEl);
  const transportAndHaulerVolumesMenuOpen = Boolean(transportAndHaulerVolumesAnchorEl);
  const commodityVolumesAndGPMenuOpen = Boolean(commodityVoulmesAndGPAnchorEl);
  const portVolumesMenuOpen = Boolean(portVolumesAnchorEl);
  const commonTradeMenuOpen = Boolean(commonTradeAnchorEl);
  const totalCarrierVolumesMenuOpen = Boolean(totalCarrierVolumesAnchorEl);
  const kpiDataMenuOpen = Boolean(kpiDataAnchorEl);

  const { data: volumnMonthly, isFetching: isVolumeMonthlyFetching } = useGetMonthlyVolumesQuery([
    formattedSelectedYear,
    formattedSelectedMonth,
  ]);
  const { data: grossProfitsMonthly, isFetching: isGrossProfitsMonthlyFetching } = useGetMonthlyGrossProfitsQuery([
    formattedSelectedYear,
    formattedSelectedMonth,
    monthlyGrossProfitsProfitType,
  ]);
  const { data: customerVolumesProfitsByMonth, isFetching: isCustomerVolumesProfitsByMonthFetching } =
    useGetCustomerVolumesProfitsByMonthQuery([
      customerVolumeProfitType,
      customerProfitType,
      formattedSelectedYear,
      formattedSelectedMonth,
    ]);
  const { data: totalCarrierVolumes, isFetching: isTotalCarrierVolumesFetching } = useGetTotalCarrierVolumesQuery([
    formattedSelectedYear,
    formattedSelectedMonth,
  ]);
  const { data: portVolumes, isFetching: isPortVolumesFetching } = useGetPortVolumesQuery([
    formattedSelectedYear,
    formattedSelectedMonth,
  ]);
  const { data: commodityVolumesProfits, isFetching: isCommodityVolumesProfitsFetching } =
    useGetCommodityVolumesProftsQuery([
      commodityBookingType,
      commodityVolumeAndProfitType,
      formattedSelectedYear,
      formattedSelectedMonth,
    ]);
  const { data: transportTypeVolumes, isFetching: isTransportTypeVolumesFetching } = useGetTransportTypeVolumesQuery([
    loadingsBookingType,
    formattedSelectedYear,
    formattedSelectedMonth,
  ]);
  const { data: merchantHaulerStats, isFetching: isMerchantHaulerStatsFetching } = useGetMerchantHauleStatsQuery([
    loadingsBookingType,
    formattedSelectedYear,
    formattedSelectedMonth,
  ]);
  const { data: portCombinationVolumes, isFetching: isPortCombinationVolumesFetching } =
    useGetPortCombinationVolumesQuery([portCombinationBookingType, formattedSelectedYear, formattedSelectedMonth]);
  const { data: containerTypeData, isFetching: isContainerTypeDataFetching } = useGetContainerTypeDataQuery([
    containerTypeDataBookingType,
    formattedSelectedYear,
    formattedSelectedMonth,
  ]);
  const { data: monthlyHaulerData, isFetching: isMonthlyHaulerDataFetching } = useGetMonthlyHaulerStatsQuery([
    monthlyHaulerStatsBookingType,
    formattedSelectedYear,
    formattedSelectedMonth,
  ]);
  const { data: commodityVolumesProfitsMonthly, isFetching: isCommodityVolumesProfitsMonthlyFetching } =
    useGetMonthlyCommodityVolumeAndProfitQuery([
      monthlyCommodityVolumeAndProfitBookingType,
      commodityVolumeAndProfitType,
      formattedSelectedYear,
      formattedSelectedMonth,
    ]);
  const { data: portVolumesMonthly, isFetching: isPortVolumesMonthlyFetching } = useGetMonthlyPortVolumesQuery([
    formattedSelectedYear,
    formattedSelectedMonth,
  ]);
  const { data: commonMonthlyPortCombinationVolumes, isFetching: isCommonMonthlyPortCombinationVolumesFetching } =
    useGetMonthlyPortCombinationVolumesQuery([
      monthlyPortCombinationBookingType,
      formattedSelectedYear,
      formattedSelectedMonth,
    ]);
  const { data: totalCarrierVolumesMonthly, isFetching: isTotalCarrierVolumesMonthlyFetching } =
    useGetMonthlyCarrierVolumesQuery([formattedSelectedYear, formattedSelectedMonth]);
  const { data: monthlyVolumesDataForKpi, isFetching: isMonthlyVolumesDataForKpiFetching } =
    useGetMonthlyVolumesForKpiQuery([formattedSelectedYear, formattedSelectedMonth]);
  const { data: cummulativeVolumesDataForKpi, isFetching: isCummulativeVolumesDataForKpiFetching } =
    useGetCummulativeVolumesForKpiQuery([formattedSelectedYear, formattedSelectedMonth], {
      skip: !!selectedMonth,
    });
  const { data: monthlyProfitsDataForKpi, isFetching: isMonthlyProfitsDataForKpiFetching } =
    useGetMonthlyProfitsForKpiQuery([formattedSelectedYear, kpiProfitType, formattedSelectedMonth]);
  const { data: cummulativeProfitsDataForKpi, isFetching: isCummulativeProfitsDataForKpiFetching } =
    useGetCummulativeProfitsForKpiQuery([formattedSelectedYear, kpiProfitType, formattedSelectedMonth], {
      skip: !!selectedMonth,
    });
  const { data: oldestBookingYear, isFetching: isOldestBookingYearFetching } = useGetOldestBookingYearQuery();

  const processedCommondityData = commodityVolumesProfits?.map(item => ({
    ...item,
    avgProfitPerContainer: item.volume > 0 ? (item.profit / item.volume).toFixed(2) : 'N/A',
  }));

  const sortedPorts = portVolumes
    ?.map(port => ({
      ...port,
      combinedVol: port.exportVol + port.importVol,
    }))
    .sort((a, b) => b.combinedVol - a.combinedVol)
    .slice(0, 5);

  const topFourContainerTypes = containerTypeData?.slice(0, 4);

  const years = Array.from({ length: currentYear - startYear + 1 }, (_, i) => startYear + i);

  useEffect(() => {
    if (oldestBookingYear !== undefined) {
      setStartYear(Number(oldestBookingYear.oldestYear));
    }
  }, [oldestBookingYear]);

  useEffect(() => {
    if (selectedYear === dayjs().year()) {
      setSelectedMonth(null);
      setFormattedSelectedMonth(`${selectedYear}--28`);
    }
  }, [selectedYear]);

  const handleYearChange = (event: SelectChangeEvent<string>) => {
    setSelectedYear(Number(event.target.value));
    setFormattedSelectedYear(`${event.target.value}-12-28`);
  };

  const handleOpenDialog = (customerData: CustomerVolumeProfitByMonth) => {
    setSelectedCustomerMonthlyData(customerData);
    setCustomerOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setCustomerOpenDialog(false);
    setSelectedCustomerMonthlyData({} as CustomerVolumeProfitByMonth);
  };

  const handleOpenCustomerVolumeAndProfitMonthlyReportDialog = () => {
    setCustomerVolumeAndProfitMonthlyReportDialog(true);
  };

  const handleCloseCustomerVolumeAndProfitMonthlyReportDialog = () => {
    setCustomerVolumeAndProfitMonthlyReportDialog(false);
  };

  const handleOpenContainerTypeMonthlyReportDialog = () => {
    setContainerTypeMonthlyReportDialog(true);
  };

  const handleOpenHaulerStatsMonthlyReportDialog = () => {
    setHaulerStatsMonthlyReportDialog(true);
  };

  const handleCloseHaulerStatsMonthlyReportDialog = () => {
    setHaulerStatsMonthlyReportDialog(false);
  };

  const handleCloseContainerTypeMonthlyReportDialog = () => {
    setContainerTypeMonthlyReportDialog(false);
  };

  const handleOpenCommodityVolumeAndProfitMonthlyReportDialog = () => {
    setCommodityVolumeAndProfitMonthlyReportDialog(true);
  };

  const handleCloseCommodityVolumeAndProfitMonthlyReportDialog = () => {
    setCommodityVolumeAndProfitMonthlyReportDialog(false);
  };

  const handleOpenPortVolumesMonthlyReportDialog = () => {
    setPortVolumesMonthlyReportDialog(true);
  };

  const handleClosePortVolumesMonthlyReportDialog = () => {
    setPortVolumesMonthlyReportDialog(false);
  };

  const handleOpenCommonMonthlyPortCombinationReportDialog = () => {
    setCommonMonthlyPortCombinationReportDialog(true);
  };

  const handleCloseCommonMonthlyPortCombinationReportDialog = () => {
    setCommonMonthlyPortCombinationReportDialog(false);
  };

  const handleOpenTotalCarrierVolumesMonthlyReportDialog = () => {
    setTotalCarrierVolumesMonthlyReportDialog(true);
  };

  const handleCloseTotalCarrierVolumesMonthlyReportDialog = () => {
    setTotalCarrierVolumesMonthlyReportDialog(false);
  };

  const handleCustomerVolumeAndProfitMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setCustomerVolumeAndProfitAnchorEl(event.currentTarget);
  };

  const handleContainerTypeMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setContainerTypeAnchorEl(event.currentTarget);
  };

  const handleSecondCardMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setSecondCardAnchorEl(event.currentTarget);
  };

  const handleTransportAndHaulerVolumesMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setTransportAndHaulerVolumesAnchorEl(event.currentTarget);
  };

  const handleCommodityVolumesAndGPMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setCommodityVolumesAndGPAnchorEl(event.currentTarget);
  };

  const handlePortVolumesMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setPortVolumesAnchorEl(event.currentTarget);
  };

  const handleCommonTradeMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setCommonTradeAnchorEl(event.currentTarget);
  };

  const handleTotalCarrierVolumesMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setTotalCarrierVolumesAnchorEl(event.currentTarget);
  };

  const handleKpiDataMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setKpiDataAnchorEl(event.currentTarget);
  };

  const handleKpiDataMenuClose = () => {
    setKpiDataAnchorEl(null);
  };
  const handlePortVolumesMenuClose = () => {
    setPortVolumesAnchorEl(null);
  };

  const handleCommodityVolumesAndGPMenuClose = () => {
    setCommodityVolumesAndGPAnchorEl(null);
  };

  const handleSecondCardMenuClose = () => {
    setSecondCardAnchorEl(null);
  };

  const handleCustomerVolumeAndProfitMenuClose = () => {
    setCustomerVolumeAndProfitAnchorEl(null);
  };

  const handleContainerTypeMenuClose = () => {
    setContainerTypeAnchorEl(null);
  };

  const handleTransportAndHaulerVolumesMenuClose = () => {
    setTransportAndHaulerVolumesAnchorEl(null);
  };

  const handleCommonTradeMenuClose = () => {
    setCommonTradeAnchorEl(null);
  };

  const handleTotalCarrierVolumesMenuClose = () => {
    setTotalCarrierVolumesAnchorEl(null);
  };

  const handleChangeSelectedMonth = (event: SelectChangeEvent<number>) => {
    const value = event.target.value as number;
    setSelectedMonth(value);
    setFormattedSelectedMonth(`${selectedYear}-${value.toString()}-28`);
  };

  const handleClearMonthSelection = () => {
    setSelectedMonth(null);
    setFormattedSelectedMonth(`${selectedYear}--28`);
  };

  const customerVolumeColumns: GridColDef[] = [
    { field: 'customerName', headerName: 'Customer', flex: 0.5 },
    { field: 'totalVolume', headerName: 'Volume', flex: 0.2 },
    { field: 'totalProfit', headerName: 'GP (£)', flex: 0.2 },
    {
      field: 'breakdown',
      headerName: 'Breakdown',
      flex: 0.2,
      renderCell: cellValues => {
        return (
          <IconButton
            sx={{ color: theme.palette.secondary[400] }}
            onClick={() => handleOpenDialog(cellValues.row as CustomerVolumeProfitByMonth)}
          >
            <InfoOutlined />
          </IconButton>
        );
      },
    },
  ];

  const merchantHaulerStatsColumns: GridColDef[] = [
    { field: 'hauler', headerName: 'Hauler', flex: 0.5 },
    {
      field: 'completedLoadings',
      headerName: 'Completed',
      flex: 0.25,
    },
    { field: 'canceledLoadings', headerName: 'Cancelled', flex: 0.25 },
  ];

  const carrierVolumeColumns: GridColDef[] = [
    { field: 'carrierName', headerName: 'Carrier', flex: 0.5 },
    {
      field: totalCarrierVolumesBookingType === 'Export' ? 'totalExportVolume' : 'totalImportVolume',
      headerName: 'Volume',
      flex: 0.5,
    },
  ];

  const monthNames: { value: number; label: string }[] = getMonthNamesFromYear(selectedYear);

  return (
    <Box>
      <Box m='1rem 2.5rem' display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
        <Header title='Analytics' />
        <Box>
          <FormControl variant='filled' sx={{ minWidth: 120, marginRight: 1 }}>
            <InputLabel id='month-dropdown-label'>Month</InputLabel>
            <Select
              labelId='month-dropdown-label'
              value={selectedMonth || ''}
              onChange={handleChangeSelectedMonth}
              displayEmpty
              renderValue={selected => {
                if (selected === null) {
                  return <Box sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>None</Box>;
                }
                return monthNames.find(month => month.value === Number(selected))?.label;
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    '& .MuiMenuItem-root.Mui-selected': {
                      backgroundColor: theme.palette.secondary[500],
                    },
                  },
                },
              }}
            >
              <MenuItem value='' onClick={handleClearMonthSelection}>
                <em>None</em>
              </MenuItem>
              {monthNames.map(month => (
                <MenuItem key={month.value} value={month.value}>
                  {month.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl variant='filled' sx={{ minWidth: 120 }}>
            <InputLabel id='year-select-label'>Year</InputLabel>
            <Select
              labelId='year-select-label'
              value={selectedYear.toString()}
              onChange={handleYearChange}
              label='Year'
              MenuProps={{
                PaperProps: {
                  sx: {
                    '& .MuiMenuItem-root.Mui-selected': {
                      backgroundColor: theme.palette.secondary[500],
                    },
                  },
                },
              }}
            >
              {years.map(year => (
                <MenuItem key={year} value={year.toString()}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>
      <Box
        display='grid'
        gap='1.5rem'
        height={'100vh'}
        sx={{
          margin: '0 2.5rem',
          gridTemplateColumns: {
            xs: '1fr',
            sm: 'repeat(2, 1fr)',
            md: 'repeat(3, 1fr)',
          },
          gridTemplateRows: 'auto',
          gridTemplateAreas: {
            xs: gridTemplate.xs,
            sm: gridTemplate.sm,
            md: gridTemplate.md,
          },
          height: '100vh',
        }}
      >
        <AnalyticsCard gridArea={'a'}>
          <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <AnalyticsCardHeader
              title={
                kpiDisplay === 'Profit' && kpiProfitType === 'Expected'
                  ? 'Expected Profit'
                  : kpiDisplay === 'Profit' && kpiProfitType === 'Actual'
                    ? 'Actual Profit'
                    : 'Volume'
              }
            />
            <Button
              variant='contained'
              onClick={handleKpiDataMenuClick}
              sx={{
                marginRight: '0.5rem',
                fontSize: '10px',
                height: '20px',
                backgroundColor: theme.palette.secondary[500],
              }}
            >
              Options
            </Button>
            <Menu anchorEl={kpiDataAnchorEl} open={kpiDataMenuOpen} onClose={handleKpiDataMenuClose}>
              <MenuItem
                onClick={() => {
                  setKpiDisplay('Volume');
                  handleKpiDataMenuClose();
                }}
                sx={{
                  backgroundColor: kpiDisplay === 'Volume' ? `${theme.palette.secondary[500]}` : 'inherit',
                  '&:hover': {
                    backgroundColor:
                      kpiDisplay === 'Volume' ? `${theme.palette.secondary[500]}` : 'rgba(132, 130, 130, 0.2)',
                  },
                }}
              >
                Volume
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setKpiDisplay('Profit');
                  setKpiProfitType('Expected');
                  handleKpiDataMenuClose();
                }}
                sx={{
                  backgroundColor:
                    kpiDisplay === 'Profit' && kpiProfitType === 'Expected'
                      ? `${theme.palette.secondary[500]}`
                      : 'inherit',
                  '&:hover': {
                    backgroundColor:
                      kpiDisplay === 'Profit' && kpiProfitType === 'Expected'
                        ? `${theme.palette.secondary[500]}`
                        : 'rgba(132, 130, 130, 0.2)',
                  },
                }}
              >
                Expected Profit
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setKpiDisplay('Profit');
                  setKpiProfitType('Actual');
                  handleKpiDataMenuClose();
                }}
                sx={{
                  backgroundColor:
                    kpiDisplay === 'Profit' && kpiProfitType === 'Actual'
                      ? `${theme.palette.secondary[500]}`
                      : 'inherit',
                  '&:hover': {
                    backgroundColor:
                      kpiDisplay === 'Profit' && kpiProfitType === 'Actual'
                        ? `${theme.palette.secondary[500]}`
                        : 'rgba(132, 130, 130, 0.2)',
                  },
                }}
              >
                Actual Profit
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setKpiBookingType('Export');
                  handleKpiDataMenuClose();
                }}
                sx={{
                  backgroundColor: kpiBookingType === 'Export' ? `${theme.palette.secondary[500]}` : 'inherit',
                  '&:hover': {
                    backgroundColor:
                      kpiBookingType === 'Export' ? `${theme.palette.secondary[500]}` : 'rgba(132, 130, 130, 0.2)',
                  },
                }}
              >
                Export
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setKpiBookingType('Import');
                  handleKpiDataMenuClose();
                }}
                sx={{
                  backgroundColor: kpiBookingType === 'Import' ? `${theme.palette.secondary[500]}` : 'inherit',
                  '&:hover': {
                    backgroundColor:
                      kpiBookingType === 'Import' ? `${theme.palette.secondary[500]}` : 'rgba(132, 130, 130, 0.2)',
                  },
                }}
              >
                Import
              </MenuItem>
            </Menu>
          </Box>
          {isMonthlyVolumesDataForKpiFetching ||
          isMonthlyProfitsDataForKpiFetching ||
          isOldestBookingYearFetching ||
          isCummulativeVolumesDataForKpiFetching ||
          isCummulativeProfitsDataForKpiFetching ? (
            <Box display='flex' justifyContent='center' alignItems='center' height='250px'>
              <CircularProgress />
            </Box>
          ) : (
            <Box>
              <Box width={'100%'} height={'100%'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Typography>Last Month</Typography>
                  <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 1, md: 3 }}>
                    <Gauge
                      width={gaugeSize} // Use the calculated size
                      height={gaugeSize} // Use the calculated size
                      value={
                        selectedMonth
                          ? kpiBookingType === 'Export' && kpiDisplay === 'Volume'
                            ? Number(monthlyVolumesDataForKpi?.volumeChange.exportChangePercentage)
                            : kpiBookingType === 'Import' && kpiDisplay === 'Volume'
                              ? Number(monthlyVolumesDataForKpi?.volumeChange.importChangePercentage)
                              : kpiBookingType === 'Export' && kpiDisplay === 'Profit'
                                ? Number(monthlyProfitsDataForKpi?.profitChange.exportChangePercentage)
                                : kpiBookingType === 'Import' && kpiDisplay === 'Profit'
                                  ? Number(monthlyProfitsDataForKpi?.profitChange.importChangePercentage)
                                  : 0
                          : 0
                      }
                      cornerRadius='50%'
                      sx={{
                        [`& .${gaugeClasses.valueText}`]: {
                          fontSize: gaugeValueFontSize,
                        },
                        [`& .${gaugeClasses.valueArc}`]: {
                          fill: kpiDisplay === 'Volume' ? theme.palette.primary[400] : theme.palette.secondary[400],
                        },
                      }}
                    />
                  </Stack>
                </Box>
                <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 1, md: 3 }}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Typography>Last Year</Typography>
                    <Gauge
                      width={gaugeSize} // Use the calculated size
                      height={gaugeSize} // Use the calculated size
                      value={
                        selectedMonth
                          ? kpiBookingType === 'Export' && kpiDisplay === 'Volume'
                            ? Number(monthlyVolumesDataForKpi?.volumeChange.exportChangeFromPreviousYearPercentage)
                            : kpiBookingType === 'Import' && kpiDisplay === 'Volume'
                              ? Number(monthlyVolumesDataForKpi?.volumeChange.importChangeFromPreviousYearPercentage)
                              : kpiBookingType === 'Export' && kpiDisplay === 'Profit'
                                ? Number(monthlyProfitsDataForKpi?.profitChange.exportChangeFromPreviousYearPercentage)
                                : kpiBookingType === 'Import' && kpiDisplay === 'Profit'
                                  ? Number(
                                      monthlyProfitsDataForKpi?.profitChange.importChangeFromPreviousYearPercentage,
                                    )
                                  : 0
                          : 0
                      }
                      cornerRadius='50%'
                      sx={{
                        [`& .${gaugeClasses.valueText}`]: {
                          fontSize: gaugeValueFontSize,
                        },
                        [`& .${gaugeClasses.valueArc}`]: {
                          fill: kpiDisplay === 'Volume' ? theme.palette.primary[400] : theme.palette.secondary[400],
                        },
                      }}
                    />
                  </Box>
                </Stack>
              </Box>
              <Grid
                container
                //rowSpacing={1} // Gap between rows
                columnSpacing={1} // Gap between columns
                width={'100%'}
                sx={{
                  marginTop: '.5rem',
                }}
              >
                <Grid item xs={6}>
                  <Box display='flex' flexDirection='column' gap='1rem' alignItems='flex-end'>
                    <Typography color='#a1a1a1'>Type:</Typography>
                    <Typography color='#a1a1a1'>{selectedMonth ? 'Month:' : 'Year:'}</Typography>
                    <Typography color='#a1a1a1'>{kpiDisplay === 'Volume' ? 'Volume:' : 'Profit:'}</Typography>
                  </Box>
                </Grid>

                <Grid item xs={6}>
                  <Box display='flex' flexDirection='column' gap='1rem' alignItems='flex-start'>
                    {/* Type Value */}
                    <Typography>{kpiBookingType}</Typography>

                    {/* Month or Year Value */}
                    <Typography>{selectedMonth ? monthlyVolumesDataForKpi?.currentMonth : selectedYear}</Typography>

                    {/* Volume or Profit Value */}
                    <Box display='flex' alignItems='center' gap='.5rem'>
                      <Typography>
                        {kpiDisplay === 'Volume'
                          ? selectedMonth
                            ? kpiBookingType === 'Export'
                              ? monthlyVolumesDataForKpi?.currentMonthVolume.exportVolume
                              : monthlyVolumesDataForKpi?.currentMonthVolume.importVolume
                            : kpiBookingType === 'Export'
                              ? cummulativeVolumesDataForKpi?.cummulativeExportVolume
                              : cummulativeVolumesDataForKpi?.cummulativeImportVolume
                          : selectedMonth
                            ? kpiBookingType === 'Export'
                              ? `£${monthlyProfitsDataForKpi?.currentMonthProfit.exportProfit}`
                              : `£${monthlyProfitsDataForKpi?.currentMonthProfit.importProfit}`
                            : kpiBookingType === 'Export'
                              ? `£${cummulativeProfitsDataForKpi?.cummulativeExportProfit}`
                              : `£${cummulativeProfitsDataForKpi?.cummulativeImportProfit}`}
                      </Typography>
                      <RenderChangeBox
                        selectedMonth={selectedMonth}
                        changePercentage={
                          kpiDisplay === 'Volume'
                            ? kpiBookingType === 'Export'
                              ? monthlyVolumesDataForKpi?.volumeChange.exportChangePercentage
                              : monthlyVolumesDataForKpi?.volumeChange.importChangePercentage
                            : kpiBookingType === 'Export'
                              ? monthlyProfitsDataForKpi?.profitChange.exportChangePercentage
                              : monthlyProfitsDataForKpi?.profitChange.importChangePercentage
                        }
                        formatChange={formatpercentageChange}
                      />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          )}
        </AnalyticsCard>
        <AnalyticsCard gridArea={'b'}>
          {selectedMonth ? (
            <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height='300px'>
              <Typography variant='h1'>N/A</Typography>
            </Box>
          ) : (
            <Box>
              <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                <AnalyticsCardHeader
                  title={
                    secondCardDisplay === 'TotalGrossProfits' && monthlyGrossProfitsProfitType === 'Expected'
                      ? 'Total Expected Gross Profits'
                      : secondCardDisplay === 'TotalGrossProfits' && monthlyGrossProfitsProfitType === 'Actual'
                        ? 'Total Actual Gross Profits'
                        : 'Total Volumes'
                  }
                />
                <Button
                  variant='contained'
                  onClick={handleSecondCardMenuClick}
                  sx={{
                    marginRight: '0.5rem',
                    fontSize: '10px',
                    height: '20px',
                    backgroundColor: theme.palette.secondary[500],
                  }}
                >
                  Options
                </Button>
                <Menu anchorEl={secondCardAnchorEl} open={secondCardMenuOpen} onClose={handleSecondCardMenuClose}>
                  <MenuItem
                    onClick={() => {
                      setSecondCardDisplay('TotalVolumes');
                      handleSecondCardMenuClose();
                    }}
                    sx={{
                      backgroundColor:
                        secondCardDisplay === 'TotalVolumes' ? `${theme.palette.secondary[500]}` : 'inherit',
                      '&:hover': {
                        backgroundColor:
                          secondCardDisplay === 'TotalVolumes'
                            ? `${theme.palette.secondary[500]}`
                            : 'rgba(132, 130, 130, 0.2)',
                      },
                    }}
                  >
                    Total Volumes
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setSecondCardDisplay('TotalGrossProfits');
                      setMonthlyGrossProfitsProfitType('Expected');
                      handleSecondCardMenuClose();
                    }}
                    sx={{
                      backgroundColor:
                        secondCardDisplay === 'TotalGrossProfits' && monthlyGrossProfitsProfitType === 'Expected'
                          ? `${theme.palette.secondary[500]}`
                          : 'inherit',
                      '&:hover': {
                        backgroundColor:
                          secondCardDisplay === 'TotalGrossProfits' && monthlyGrossProfitsProfitType === 'Expected'
                            ? `${theme.palette.secondary[500]}`
                            : 'rgba(132, 130, 130, 0.2)',
                      },
                    }}
                  >
                    Total Expected Gross Profits
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setSecondCardDisplay('TotalGrossProfits');
                      setMonthlyGrossProfitsProfitType('Actual');
                      handleSecondCardMenuClose();
                    }}
                    sx={{
                      backgroundColor:
                        secondCardDisplay === 'TotalGrossProfits' && monthlyGrossProfitsProfitType === 'Actual'
                          ? `${theme.palette.secondary[500]}`
                          : 'inherit',
                      '&:hover': {
                        backgroundColor:
                          secondCardDisplay === 'TotalGrossProfits' && monthlyGrossProfitsProfitType === 'Actual'
                            ? `${theme.palette.secondary[500]}`
                            : 'rgba(132, 130, 130, 0.2)',
                      },
                    }}
                  >
                    Total Actual Gross Profits
                  </MenuItem>
                </Menu>
              </Box>
              {secondCardDisplay === 'TotalVolumes' ? (
                <Box>
                  {isVolumeMonthlyFetching || isOldestBookingYearFetching ? (
                    <Box display='flex' justifyContent='center' alignItems='center' height='250px'>
                      <CircularProgress />
                    </Box>
                  ) : (
                    <Box height={'250px'} mt={'1rem'}>
                      <ResponsiveContainer width={'100%'} height={'100%'}>
                        <AreaChart
                          width={500}
                          height={400}
                          data={volumnMonthly}
                          margin={{
                            right: 30,
                          }}
                        >
                          <defs>
                            <linearGradient id='colorExportVolume' x1='0' y1='0' x2='0' y2='1'>
                              <stop offset='5%' stopColor={theme.palette.primary[300]} stopOpacity={0.5} />
                              <stop offset='95%' stopColor={theme.palette.primary[300]} stopOpacity={0} />
                            </linearGradient>
                            <linearGradient id='colorImportVolume' x1='0' y1='0' x2='0' y2='1'>
                              <stop offset='5%' stopColor={theme.palette.secondary[300]} stopOpacity={0.5} />
                              <stop offset='95%' stopColor={theme.palette.secondary[300]} stopOpacity={0} />
                            </linearGradient>
                          </defs>
                          <XAxis dataKey='month' tickLine={false} style={{ fontSize: '10px' }} />
                          <YAxis tickLine={false} axisLine={{ strokeWidth: '0' }} style={{ fontSize: '10px' }} />
                          <Tooltip
                            cursor={false}
                            contentStyle={{
                              backgroundColor: theme.palette.background.alt,
                            }}
                            labelStyle={{ color: theme.palette.text.primary }}
                            itemStyle={{ color: theme.palette.text.primary }}
                          />
                          <Legend
                            wrapperStyle={{
                              fontSize: '10px',
                            }}
                          />
                          <Area
                            type='monotone'
                            dataKey='exportVolume'
                            dot={true}
                            stroke={theme.palette.primary.main}
                            fillOpacity={1}
                            fill='url(#colorExportVolume)'
                            name='Export'
                          />
                          <Area
                            type='monotone'
                            dataKey='importVolume'
                            dot={true}
                            stroke={theme.palette.secondary.main}
                            fillOpacity={1}
                            fill='url(#colorImportVolume)'
                            name='Import'
                          />
                        </AreaChart>
                      </ResponsiveContainer>
                    </Box>
                  )}
                </Box>
              ) : (
                <Box>
                  {isGrossProfitsMonthlyFetching || isOldestBookingYearFetching ? (
                    <Box display='flex' justifyContent='center' alignItems='center' height='250px'>
                      <CircularProgress />
                    </Box>
                  ) : (
                    <Box height={'250px'} mt={'1rem'}>
                      <CustomLineChart data={grossProfitsMonthly} key1='exportGrossProfit' key2='importGrossProfit' />
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          )}
        </AnalyticsCard>
        <AnalyticsCard gridArea={'c'}>
          <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <AnalyticsCardHeader title='Port Volumes' />
            <Box>
              <Button
                variant='contained'
                onClick={handlePortVolumesMenuClick}
                sx={{
                  marginRight: '0.5rem',
                  fontSize: '10px',
                  height: '20px',
                  backgroundColor: theme.palette.secondary[500],
                }}
              >
                Options
              </Button>
              <Menu anchorEl={portVolumesAnchorEl} open={portVolumesMenuOpen} onClose={handlePortVolumesMenuClose}>
                <MenuItem
                  onClick={() => {
                    handleOpenPortVolumesMonthlyReportDialog();
                    handlePortVolumesMenuClose();
                  }}
                >
                  Monthly Report
                </MenuItem>
              </Menu>
            </Box>
          </Box>
          {isPortVolumesFetching || isPortVolumesMonthlyFetching || isOldestBookingYearFetching ? (
            <Box display='flex' justifyContent='center' alignItems='center' height='180px'>
              <CircularProgress />
            </Box>
          ) : (
            <Box height={'180px'}>
              <ResponsiveContainer width='100%' height='100%'>
                <BarChart
                  width={500}
                  height={180}
                  data={sortedPorts}
                  margin={{
                    top: 10,
                    right: 0,
                    left: 0,
                    bottom: 0,
                  }}
                >
                  <defs>
                    <linearGradient id='colorExportPortVol' x1='0' y1='0' x2='0' y2='1'>
                      <stop offset='5%' stopColor={theme.palette.primary[300]} stopOpacity={0.8} />
                      <stop offset='95%' stopColor={theme.palette.primary[300]} stopOpacity={0} />
                    </linearGradient>
                    <linearGradient id='colorImportPortVol' x1='0' y1='0' x2='0' y2='1'>
                      <stop offset='5%' stopColor={theme.palette.secondary[300]} stopOpacity={0.8} />
                      <stop offset='95%' stopColor={theme.palette.secondary[300]} stopOpacity={0} />
                    </linearGradient>
                  </defs>
                  <CartesianGrid vertical={false} stroke={theme.palette.grey[800]} />
                  <XAxis dataKey='portCode' axisLine={false} tickLine={false} style={{ fontSize: '10px' }} />
                  <YAxis axisLine={false} tickLine={false} style={{ fontSize: '10px' }} />
                  <Bar dataKey='exportVol' fill='url(#colorExportPortVol)' />
                  <Bar dataKey='importVol' fill='url(#colorImportPortVol)' />
                  <Tooltip
                    cursor={false}
                    contentStyle={{
                      backgroundColor: theme.palette.background.alt,
                    }}
                    labelStyle={{ color: theme.palette.text.primary }}
                    itemStyle={{ color: theme.palette.text.primary }}
                  />
                </BarChart>
              </ResponsiveContainer>
            </Box>
          )}
        </AnalyticsCard>
        <AnalyticsCard gridArea={'d'}>
          <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <AnalyticsCardHeader title='Total Customer Volume and Profit' />
            <Box>
              <Button
                variant='contained'
                onClick={handleCustomerVolumeAndProfitMenuClick}
                sx={{
                  marginRight: '0.5rem',
                  fontSize: '10px',
                  height: '20px',
                  backgroundColor: theme.palette.secondary[500],
                }}
              >
                Options
              </Button>
              <Menu
                anchorEl={customerVolumeAndProfitanchorEl}
                open={customerVolumeAndProfitMenuOpen}
                onClose={handleCustomerVolumeAndProfitMenuClose}
              >
                <MenuItem
                  onClick={() => {
                    setCustomerVolumeProfitType('Export');
                    handleCustomerVolumeAndProfitMenuClose();
                  }}
                  sx={{
                    backgroundColor: customerVolumeProfitType === 'Export' ? theme.palette.secondary[500] : 'inherit',
                    '&:hover': {
                      backgroundColor:
                        customerVolumeProfitType === 'Export'
                          ? theme.palette.secondary[500]
                          : 'rgba(132, 130, 130, 0.2)',
                    },
                  }}
                >
                  Export
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setCustomerVolumeProfitType('Import');
                    handleCustomerVolumeAndProfitMenuClose();
                  }}
                  sx={{
                    backgroundColor: customerVolumeProfitType === 'Import' ? theme.palette.secondary[500] : 'inherit',
                    '&:hover': {
                      backgroundColor:
                        customerVolumeProfitType === 'Import'
                          ? theme.palette.secondary[500]
                          : 'rgba(132, 130, 130, 0.2)',
                    },
                  }}
                >
                  Import
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setCustomerProfitType('Expected');
                    handleCustomerVolumeAndProfitMenuClose();
                  }}
                  sx={{
                    backgroundColor: customerProfitType === 'Expected' ? theme.palette.secondary[500] : 'inherit',
                    '&:hover': {
                      backgroundColor:
                        customerProfitType === 'Expected' ? theme.palette.secondary[500] : 'rgba(132, 130, 130, 0.2)',
                    },
                  }}
                >
                  Expected Profit
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setCustomerProfitType('Actual');
                    handleCustomerVolumeAndProfitMenuClose();
                  }}
                  sx={{
                    backgroundColor: customerProfitType === 'Actual' ? theme.palette.secondary[500] : 'inherit',
                    '&:hover': {
                      backgroundColor:
                        customerProfitType === 'Actual' ? theme.palette.secondary[500] : 'rgba(132, 130, 130, 0.2)',
                    },
                  }}
                >
                  Actual Profit
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleOpenCustomerVolumeAndProfitMonthlyReportDialog();
                    handleCustomerVolumeAndProfitMenuClose();
                  }}
                >
                  Monthly Report
                </MenuItem>
              </Menu>
            </Box>
          </Box>

          {isCustomerVolumesProfitsByMonthFetching || isOldestBookingYearFetching ? (
            <Box display='flex' justifyContent='center' alignItems='center' height='155px'>
              <CircularProgress />
            </Box>
          ) : (
            <Box
              p='0 0.5rem'
              height='155px'
              sx={{
                '& .MuiDataGrid-root': {
                  fontSize: '11px',
                  color: theme.palette.grey[500],
                  border: 'none',
                },
                '& .MuiDataGrid-cell': {
                  borderBottom: `1px solid ${theme.palette.grey[800]} !important`,
                },
                '& .MuiDataGrid-columnHeaders': {
                  borderBottom: `1px solid ${theme.palette.grey[800]} !important`,
                },
                '& .MuiDataGrid-columnSeparator': {
                  visibility: 'hidden',
                },
              }}
            >
              <DataGrid
                columnHeaderHeight={25}
                rowHeight={35}
                hideFooter={true}
                rows={customerVolumesProfitsByMonth || []}
                getRowId={row => row.customerId}
                columns={customerVolumeColumns}
              />
            </Box>
          )}
        </AnalyticsCard>
        <AnalyticsCard gridArea={'e'}>
          <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <AnalyticsCardHeader title='Transport & Hauler Volumes' />
            <Box>
              <Button
                variant='contained'
                onClick={handleTransportAndHaulerVolumesMenuClick}
                sx={{
                  marginRight: '0.5rem',
                  fontSize: '10px',
                  height: '20px',
                  backgroundColor: theme.palette.secondary[500],
                }}
              >
                Options
              </Button>
              <Menu
                anchorEl={transportAndHaulerVolumesAnchorEl}
                open={transportAndHaulerVolumesMenuOpen}
                onClose={handleTransportAndHaulerVolumesMenuClose}
              >
                <MenuItem
                  onClick={() => {
                    setLoadingsBookingType('Export');
                    setMonthlyHaulerStatsBookingType('Export');
                    handleTransportAndHaulerVolumesMenuClose();
                  }}
                  sx={{
                    backgroundColor:
                      loadingsBookingType === 'Export' && monthlyHaulerStatsBookingType === 'Export'
                        ? theme.palette.secondary[500]
                        : 'inherit',
                    '&:hover': {
                      backgroundColor:
                        loadingsBookingType === 'Export' && monthlyHaulerStatsBookingType === 'Export'
                          ? theme.palette.secondary[500]
                          : 'rgba(132, 130, 130, 0.2)',
                    },
                  }}
                >
                  Export
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setLoadingsBookingType('Import');
                    setMonthlyHaulerStatsBookingType('Import');
                    handleTransportAndHaulerVolumesMenuClose();
                  }}
                  sx={{
                    backgroundColor:
                      loadingsBookingType === 'Import' && monthlyHaulerStatsBookingType === 'Import'
                        ? theme.palette.secondary[500]
                        : 'inherit',
                    '&:hover': {
                      backgroundColor:
                        loadingsBookingType === 'Import' && monthlyHaulerStatsBookingType === 'Import'
                          ? theme.palette.secondary[500]
                          : 'rgba(132, 130, 130, 0.2)',
                    },
                  }}
                >
                  Import
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleOpenHaulerStatsMonthlyReportDialog();
                    handleTransportAndHaulerVolumesMenuClose();
                  }}
                >
                  Monthly Report
                </MenuItem>
              </Menu>
            </Box>
          </Box>
          {isTransportTypeVolumesFetching ||
          isMerchantHaulerStatsFetching ||
          isMonthlyHaulerDataFetching ||
          isOldestBookingYearFetching ? (
            <Box display='flex' justifyContent='center' alignItems='center' height='100px'>
              <CircularProgress />
            </Box>
          ) : (
            <Box display='grid' gridTemplateColumns='1fr 3fr' height='100px' margin={'0rem 1rem'} gap={'0.5rem'}>
              <ResponsiveContainer width='100%' height='100%'>
                <PieChart>
                  <Pie
                    data={transportTypeVolumes?.loadingTypeVolumes || []}
                    dataKey='volume'
                    nameKey='type'
                    cx='50%'
                    cy='50%'
                    outerRadius={22}
                    fill={theme.palette.primary.main}
                    stroke={theme.palette.primary[300]}
                  />
                  <Pie
                    data={transportTypeVolumes?.haulageVolumes || []}
                    dataKey='volume'
                    nameKey='type'
                    cx='50%'
                    cy='50%'
                    innerRadius={32}
                    outerRadius={45}
                    fill={theme.palette.secondary.main}
                    stroke={theme.palette.secondary[200]}
                  />
                  <Tooltip
                    cursor={false}
                    contentStyle={{
                      backgroundColor: theme.palette.background.alt,
                    }}
                    labelStyle={{ color: theme.palette.text.primary }}
                    itemStyle={{ color: theme.palette.text.primary }}
                    formatter={(value, name, props) => {
                      const { type, volume } = props.payload;
                      return [`${type}: ${volume}`];
                    }}
                  />
                </PieChart>
              </ResponsiveContainer>
              <Box
                height={'100px'}
                sx={{
                  '& .MuiDataGrid-root': {
                    fontSize: '11px',
                    color: theme.palette.grey[500],
                    border: 'none',
                  },
                  '& .MuiDataGrid-cell': {
                    borderBottom: `1px solid ${theme.palette.grey[800]} !important`,
                  },
                  '& .MuiDataGrid-columnHeaders': {
                    borderBottom: `1px solid ${theme.palette.grey[800]} !important`,
                  },
                  '& .MuiDataGrid-columnSeparator': {
                    visibility: 'hidden',
                  },
                }}
              >
                <DataGrid
                  columnHeaderHeight={20}
                  rowHeight={25}
                  hideFooter={true}
                  rows={merchantHaulerStats || []}
                  getRowId={row => row.hauler}
                  columns={merchantHaulerStatsColumns}
                />
              </Box>
            </Box>
          )}
        </AnalyticsCard>
        <AnalyticsCard gridArea={'f'}>
          <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <AnalyticsCardHeader title='Common Trade Routes' />
            <Box>
              <Button
                variant='contained'
                onClick={handleCommonTradeMenuClick}
                sx={{
                  marginRight: '0.5rem',
                  fontSize: '10px',
                  height: '20px',
                  backgroundColor: theme.palette.secondary[500],
                }}
              >
                Options
              </Button>
              <Menu anchorEl={commonTradeAnchorEl} open={commonTradeMenuOpen} onClose={handleCommonTradeMenuClose}>
                <MenuItem
                  onClick={() => {
                    setPortCombinationBookingType('Export');
                    setMonthlyPortCombinationBookingType('Export');
                    handleCommonTradeMenuClose();
                  }}
                  sx={{
                    backgroundColor:
                      portCombinationBookingType === 'Export' ? `${theme.palette.secondary[500]}` : 'inherit',
                    '&:hover': {
                      backgroundColor:
                        portCombinationBookingType === 'Export'
                          ? `${theme.palette.secondary[500]}`
                          : 'rgba(132, 130, 130, 0.2)',
                    },
                  }}
                >
                  Export
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setPortCombinationBookingType('Import');
                    setMonthlyPortCombinationBookingType('Import');
                    handleCommonTradeMenuClose();
                  }}
                  sx={{
                    backgroundColor:
                      portCombinationBookingType === 'Import' ? `${theme.palette.secondary[500]}` : 'inherit',
                    '&:hover': {
                      backgroundColor:
                        portCombinationBookingType === 'Import'
                          ? `${theme.palette.secondary[500]}`
                          : 'rgba(132, 130, 130, 0.2)',
                    },
                  }}
                >
                  Import
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleOpenCommonMonthlyPortCombinationReportDialog();
                    handleCommonTradeMenuClose();
                  }}
                >
                  Monthly Report
                </MenuItem>
              </Menu>
            </Box>
          </Box>
          {isPortCombinationVolumesFetching ||
          isCommonMonthlyPortCombinationVolumesFetching ||
          isOldestBookingYearFetching ? (
            <Box display='flex' justifyContent='center' alignItems='center' height='180px'>
              <CircularProgress />
            </Box>
          ) : (
            <Box height={'180px'}>
              <ResponsiveContainer width='100%' height='100%'>
                <BarChart
                  width={500}
                  height={180}
                  data={portCombinationVolumes}
                  margin={{
                    top: 10,
                    right: 0,
                    left: 0,
                    bottom: 0,
                  }}
                >
                  <defs>
                    <linearGradient id='colorGradientExport' x1='0' y1='0' x2='0' y2='1'>
                      <stop offset='5%' stopColor={theme.palette.primary[300]} stopOpacity={0.8} />
                      <stop offset='95%' stopColor={theme.palette.primary[300]} stopOpacity={0} />
                    </linearGradient>
                    <linearGradient id='colorGradientImport' x1='0' y1='0' x2='0' y2='1'>
                      <stop offset='5%' stopColor={theme.palette.secondary[300]} stopOpacity={0.8} />
                      <stop offset='95%' stopColor={theme.palette.secondary[300]} stopOpacity={0} />
                    </linearGradient>
                  </defs>
                  <CartesianGrid vertical={false} stroke={theme.palette.grey[800]} />
                  <XAxis dataKey='combination' axisLine={false} tickLine={false} style={{ fontSize: '10px' }} />
                  <YAxis axisLine={false} tickLine={false} style={{ fontSize: '10px' }} />
                  <Bar
                    dataKey='totalVolume'
                    fill={`url(#${
                      portCombinationBookingType === 'Export' ? 'colorGradientExport' : 'colorGradientImport'
                    })`}
                    barSize={35}
                  />
                  <Tooltip
                    cursor={false}
                    contentStyle={{
                      backgroundColor: theme.palette.background.alt,
                    }}
                    labelStyle={{ color: theme.palette.text.primary }}
                    itemStyle={{ color: theme.palette.text.primary }}
                  />
                </BarChart>
              </ResponsiveContainer>
            </Box>
          )}
        </AnalyticsCard>
        <AnalyticsCard gridArea={'g'}>
          <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <AnalyticsCardHeader title='Carrier Volumes' />
            <Box>
              <Button
                variant='contained'
                onClick={handleTotalCarrierVolumesMenuClick}
                sx={{
                  marginRight: '0.5rem',
                  fontSize: '10px',
                  height: '20px',
                  backgroundColor: theme.palette.secondary[500],
                }}
              >
                Options
              </Button>
              <Menu
                anchorEl={totalCarrierVolumesAnchorEl}
                open={totalCarrierVolumesMenuOpen}
                onClose={handleTotalCarrierVolumesMenuClose}
              >
                <MenuItem
                  onClick={() => {
                    setTotalCarrierVolumesBookingType('Export');
                    handleTotalCarrierVolumesMenuClose();
                  }}
                  sx={{
                    backgroundColor:
                      totalCarrierVolumesBookingType === 'Export' ? `${theme.palette.secondary[500]}` : 'inherit',
                    '&:hover': {
                      backgroundColor:
                        totalCarrierVolumesBookingType === 'Export'
                          ? `${theme.palette.secondary[500]}`
                          : 'rgba(132, 130, 130, 0.2)',
                    },
                  }}
                >
                  Export
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setTotalCarrierVolumesBookingType('Import');
                    handleTotalCarrierVolumesMenuClose();
                  }}
                  sx={{
                    backgroundColor:
                      totalCarrierVolumesBookingType === 'Import' ? `${theme.palette.secondary[500]}` : 'inherit',
                    '&:hover': {
                      backgroundColor:
                        totalCarrierVolumesBookingType === 'Import'
                          ? `${theme.palette.secondary[500]}`
                          : 'rgba(132, 130, 130, 0.2)',
                    },
                  }}
                >
                  Import
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleOpenTotalCarrierVolumesMonthlyReportDialog();
                    handleTotalCarrierVolumesMenuClose();
                  }}
                >
                  Monthly Report
                </MenuItem>
              </Menu>
            </Box>
          </Box>
          {isTotalCarrierVolumesFetching || isTotalCarrierVolumesMonthlyFetching || isOldestBookingYearFetching ? (
            <Box display='flex' justifyContent='center' alignItems='center' height='225px'>
              <CircularProgress />
            </Box>
          ) : (
            <Box display='flex' justifyContent='space-between' height='225px' padding={'1rem'} gap={'1rem'}>
              {totalCarrierVolumesBookingType === 'Export' ? (
                <ResponsiveContainer width='50%' height='100%'>
                  <CustomPieChart
                    data={totalCarrierVolumes?.exports || []}
                    color={theme.palette.primary.main}
                    type='Export'
                  />
                </ResponsiveContainer>
              ) : (
                <ResponsiveContainer width='50%' height='100%'>
                  <CustomPieChart
                    data={totalCarrierVolumes?.imports || []}
                    color={theme.palette.secondary.main}
                    type='Import'
                  />
                </ResponsiveContainer>
              )}
              <Box
                width={'50%'}
                marginTop={'3rem'}
                height={'120px'}
                sx={{
                  '& .MuiDataGrid-root': {
                    fontSize: '11px',
                    color: theme.palette.grey[500],
                    border: 'none',
                  },
                  '& .MuiDataGrid-cell': {
                    borderBottom: `1px solid ${theme.palette.grey[800]} !important`,
                  },
                  '& .MuiDataGrid-columnHeaders': {
                    borderBottom: `1px solid ${theme.palette.grey[800]} !important`,
                  },
                  '& .MuiDataGrid-columnSeparator': {
                    visibility: 'hidden',
                  },
                }}
              >
                <DataGrid
                  columnHeaderHeight={20}
                  rowHeight={25}
                  hideFooter={true}
                  rows={totalCarrierVolumesMonthly || []}
                  getRowId={row => row.carrierId}
                  columns={carrierVolumeColumns}
                />
              </Box>
            </Box>
          )}
        </AnalyticsCard>
        <AnalyticsCard gridArea={'h'}>
          {/* <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY ?? ""}>
          <Box height={"100%"} width={"100%"} padding={"1rem"}>
            <Map
              zoom={5}
              center={{
                lat: 53.6781,
                lng: -3.436,
              }}
              disableDefaultUI={true}
              styles={mapStyling}
            ></Map>
          </Box>
        </APIProvider> */}
          <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <AnalyticsCardHeader title='Commodity Volumes & GP' />
            <Box>
              <Button
                variant='contained'
                onClick={handleCommodityVolumesAndGPMenuClick}
                sx={{
                  marginRight: '0.5rem',
                  fontSize: '10px',
                  height: '20px',
                  backgroundColor: theme.palette.secondary[500],
                }}
              >
                Options
              </Button>
              <Menu
                anchorEl={commodityVoulmesAndGPAnchorEl}
                open={commodityVolumesAndGPMenuOpen}
                onClose={handleCommodityVolumesAndGPMenuClose}
              >
                <MenuItem
                  onClick={() => {
                    setCommodityBookingType('Export');
                    setMonthlyCommodityVolumeAndProfitBookingType('Export');
                    handleCommodityVolumesAndGPMenuClose();
                  }}
                  sx={{
                    backgroundColor: commodityBookingType === 'Export' ? `${theme.palette.secondary[500]}` : 'inherit',
                    '&:hover': {
                      backgroundColor:
                        commodityBookingType === 'Export'
                          ? `${theme.palette.secondary[500]}`
                          : 'rgba(132, 130, 130, 0.2)',
                    },
                  }}
                >
                  Export
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setCommodityBookingType('Import');
                    setMonthlyCommodityVolumeAndProfitBookingType('Import');
                    handleCommodityVolumesAndGPMenuClose();
                  }}
                  sx={{
                    backgroundColor: commodityBookingType === 'Import' ? `${theme.palette.secondary[500]}` : 'inherit',
                    '&:hover': {
                      backgroundColor:
                        commodityBookingType === 'Import'
                          ? `${theme.palette.secondary[500]}`
                          : 'rgba(132, 130, 130, 0.2)',
                    },
                  }}
                >
                  Import
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setCommodityVolumeAndProfitType('Expected');
                    handleCommodityVolumesAndGPMenuClose();
                  }}
                  sx={{
                    backgroundColor:
                      commodityVolumeAndProfitType === 'Expected' ? `${theme.palette.secondary[500]}` : 'inherit',
                    '&:hover': {
                      backgroundColor:
                        commodityVolumeAndProfitType === 'Expected'
                          ? `${theme.palette.secondary[500]}`
                          : 'rgba(132, 130, 130, 0.2)',
                    },
                  }}
                >
                  Expected Profit
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setCommodityVolumeAndProfitType('Actual');
                    handleCommodityVolumesAndGPMenuClose();
                  }}
                  sx={{
                    backgroundColor:
                      commodityVolumeAndProfitType === 'Actual' ? `${theme.palette.secondary[500]}` : 'inherit',
                    '&:hover': {
                      backgroundColor:
                        commodityVolumeAndProfitType === 'Actual'
                          ? `${theme.palette.secondary[500]}`
                          : 'rgba(132, 130, 130, 0.2)',
                    },
                  }}
                >
                  Actual Profit
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleOpenCommodityVolumeAndProfitMonthlyReportDialog();
                    handleCommodityVolumesAndGPMenuClose();
                  }}
                >
                  Monthly Report
                </MenuItem>
              </Menu>
            </Box>
          </Box>
          {isCommodityVolumesProfitsFetching ||
          isCommodityVolumesProfitsMonthlyFetching ||
          isOldestBookingYearFetching ? (
            <Box display='flex' justifyContent='center' alignItems='center' height={270} width='100%'>
              <CircularProgress />
            </Box>
          ) : (
            <ResponsiveContainer width='100%' height={270}>
              <BarChart
                layout='vertical'
                data={processedCommondityData}
                margin={{
                  top: 10,
                  right: 30,
                  left: 20,
                  bottom: 0,
                }}
              >
                <defs>
                  <linearGradient id='colorVolume' x1='0' y1='0' x2='0' y2='1'>
                    <stop offset='5%' stopColor={theme.palette.primary[300]} stopOpacity={0.5} />
                    <stop offset='95%' stopColor={theme.palette.primary[300]} stopOpacity={0} />
                  </linearGradient>
                </defs>
                <CartesianGrid vertical={false} stroke={theme.palette.grey[800]} />
                <XAxis axisLine={false} tickLine={false} style={{ fontSize: '10px' }} type='number' />
                <YAxis
                  dataKey='cargoDescription'
                  type='category'
                  axisLine={false}
                  tickLine={false}
                  style={{ fontSize: '10px' }}
                />
                <Tooltip
                  cursor={false}
                  contentStyle={{
                    backgroundColor: theme.palette.background.alt,
                  }}
                  labelStyle={{ color: theme.palette.text.primary }}
                  itemStyle={{ color: theme.palette.text.primary }}
                  formatter={(value, name, props) => {
                    const { volume, profit } = props.payload;
                    return [`Volume: ${volume}`, `Profit: £${profit}`];
                  }}
                />
                <Legend
                  wrapperStyle={{
                    fontSize: '10px',
                  }}
                />
                <Bar dataKey='volume' fill='url(#colorVolume)' name='Volume (CN)' barSize={20}>
                  <LabelList
                    dataKey='avgProfitPerContainer'
                    position='top'
                    formatter={(value: any) => `Profit/CN: £${value}`}
                    style={{
                      fontSize: '9px',
                      fill: `${theme.palette.secondary[100]}`,
                    }}
                  />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          )}
        </AnalyticsCard>
        <AnalyticsCard gridArea={'i'}>
          <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <AnalyticsCardHeader title='Container Type' />
            <Box>
              <Button
                variant='contained'
                onClick={handleContainerTypeMenuClick}
                sx={{
                  marginRight: '0.5rem',
                  fontSize: '10px',
                  height: '20px',
                  backgroundColor: theme.palette.secondary[500],
                }}
              >
                Options
              </Button>
              <Menu
                anchorEl={containerTypeAnchorEl}
                open={containerTypeMenuOpen}
                onClose={handleContainerTypeMenuClose}
              >
                <MenuItem
                  onClick={() => {
                    setContainerTypeDataBookingType('Export');
                    handleContainerTypeMenuClose();
                  }}
                  sx={{
                    backgroundColor:
                      containerTypeDataBookingType === 'Export' ? `${theme.palette.secondary[500]}` : 'inherit',
                    '&:hover': {
                      backgroundColor:
                        containerTypeDataBookingType === 'Export'
                          ? `${theme.palette.secondary[500]}`
                          : 'rgba(132, 130, 130, 0.2)',
                    },
                  }}
                >
                  Export
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setContainerTypeDataBookingType('Import');
                    handleContainerTypeMenuClose();
                  }}
                  sx={{
                    backgroundColor:
                      containerTypeDataBookingType === 'Import' ? `${theme.palette.secondary[500]}` : 'inherit',
                    '&:hover': {
                      backgroundColor:
                        containerTypeDataBookingType === 'Import'
                          ? `${theme.palette.secondary[500]}`
                          : 'rgba(132, 130, 130, 0.2)',
                    },
                  }}
                >
                  Import
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleOpenContainerTypeMonthlyReportDialog();
                    handleContainerTypeMenuClose();
                  }}
                >
                  Monthly Report
                </MenuItem>
              </Menu>
            </Box>
          </Box>
          {isContainerTypeDataFetching || isOldestBookingYearFetching ? (
            <Box display='flex' justifyContent='center' alignItems='center' height={270} width='100%'>
              <CircularProgress />
            </Box>
          ) : (
            <ResponsiveContainer width='100%' height={270}>
              <BarChart
                layout='vertical'
                data={topFourContainerTypes}
                margin={{
                  top: 10,
                  right: 30,
                  left: 20,
                  bottom: 0,
                }}
              >
                <defs>
                  <linearGradient id='colorVolume' x1='0' y1='0' x2='0' y2='1'>
                    <stop offset='5%' stopColor={theme.palette.primary[300]} stopOpacity={0.5} />
                    <stop offset='95%' stopColor={theme.palette.primary[300]} stopOpacity={0} />
                  </linearGradient>
                </defs>
                <CartesianGrid vertical={false} stroke={theme.palette.grey[800]} />
                <XAxis axisLine={false} tickLine={false} style={{ fontSize: '10px' }} type='number' />
                <YAxis
                  dataKey='containerType'
                  type='category'
                  axisLine={false}
                  tickLine={false}
                  style={{ fontSize: '10px' }}
                />
                <Tooltip
                  cursor={false}
                  contentStyle={{
                    backgroundColor: theme.palette.background.alt,
                  }}
                  labelStyle={{ color: theme.palette.text.primary }}
                  itemStyle={{ color: theme.palette.text.primary }}
                  formatter={(value, name, props) => {
                    const { totalVolume } = props.payload;
                    return [`Volume: ${totalVolume}`];
                  }}
                />
                <Legend
                  wrapperStyle={{
                    fontSize: '10px',
                  }}
                />
                <Bar dataKey='totalVolume' fill='url(#colorVolume)' name='Volume (CN)' barSize={20}>
                  <LabelList
                    dataKey='totalVolume'
                    position='top'
                    formatter={(value: any) => `Volume: ${value}`}
                    style={{
                      fontSize: '9px',
                      fill: `${theme.palette.secondary[100]}`,
                    }}
                  />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          )}
        </AnalyticsCard>
        <CustomerMonthlyStats
          open={customerOpenDialog}
          handleClose={handleCloseDialog}
          customerData={selectedCustomerMonthlyData}
        />
        <CustomerVolumeAndProfitMonthlyReportTable
          open={customerVolumeAndProfitMonthlyReportDialog}
          handleClose={handleCloseCustomerVolumeAndProfitMonthlyReportDialog}
          data={customerVolumesProfitsByMonth || []}
          profitType={customerProfitType}
          selectedYear={selectedYear}
          selectedMonth={selectedMonth}
        />
        <ContainerTypeMonthlyReportTable
          open={containerTypeMonthlyReportDialog}
          handleClose={handleCloseContainerTypeMonthlyReportDialog}
          data={containerTypeData || []}
          bookingType={containerTypeDataBookingType}
          selectedYear={selectedYear}
          selectedMonth={selectedMonth}
        />
        <MonthlyHaulerStatsTable
          open={monthlyHaulerStatsMonthlyReportDialog}
          handleClose={handleCloseHaulerStatsMonthlyReportDialog}
          data={monthlyHaulerData || []}
          bookingType={monthlyHaulerStatsBookingType}
          selectedYear={selectedYear}
          selectedMonth={selectedMonth}
        />
        <MonthlyCommodityVolumeAndProfitTable
          open={commodityVolumeAndProfitMonthlyReportDialog}
          handleClose={handleCloseCommodityVolumeAndProfitMonthlyReportDialog}
          data={commodityVolumesProfitsMonthly || []}
          bookingType={monthlyCommodityVolumeAndProfitBookingType}
          profitType={commodityVolumeAndProfitType}
          selectedYear={selectedYear}
          selectedMonth={selectedMonth}
        />
        <MonthlyPortVolumeTable
          open={portVolumesMonthlyReportDialog}
          handleClose={handleClosePortVolumesMonthlyReportDialog}
          data={portVolumesMonthly || []}
          selectedYear={selectedYear}
          selectedMonth={selectedMonth}
        />
        <MonthlyPortCombinationVolumesTable
          open={commonMonthlyPortCombinationReportDialog}
          handleClose={handleCloseCommonMonthlyPortCombinationReportDialog}
          data={commonMonthlyPortCombinationVolumes || []}
          bookingType={monthlyPortCombinationBookingType}
          selectedYear={selectedYear}
          selectedMonth={selectedMonth}
        />
        <MonthlyCarrierVolumeTable
          open={totalCarrierVolumesMonthlyReportDialog}
          handleClose={handleCloseTotalCarrierVolumesMonthlyReportDialog}
          data={totalCarrierVolumesMonthly || []}
          bookingType={totalCarrierVolumesBookingType}
          selectedYear={selectedYear}
          selectedMonth={selectedMonth}
        />
      </Box>
    </Box>
  );
};

export default AnalyticsDashboard;
