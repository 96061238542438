import { ClearOutlined } from '@mui/icons-material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Autocomplete, Box, Button, Drawer, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import MultiSelectAutoComplete from 'components/MultiSelectAutoComplete';

import {
  blStatuses,
  BookingFilterLabels,
  bookingStatuses,
  containerTypes,
  EnquiryFilterLabels,
  enquiryStatuses,
  incoterms,
} from 'dataAssets/constants';
import dayjs from 'dayjs';
import { TeamType } from 'models/booking.model';
import { FilterType, VendorType } from 'models/index.model';
import { default as React, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { clearBookingsFilters, clearEnquiriesFilters, setBookingsFilters, setEnquiriesFilters } from 'state';
import { useGetCustomersQuery, useGetPortsQuery, useGetUserPreferenceQuery, useGetVendorsQuery } from 'state/api';
import { generateMenuItemsFromArray } from 'utils/generateMenuItemsFromArray';

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

interface SidebarProps {
  type: string;
  open: boolean;
  onClose: () => void;
  teams: TeamType[] | undefined;
}

const Sidebar: React.FC<SidebarProps> = ({ type, open, onClose, teams }) => {
  const dispatch = useDispatch();
  const userEmail = useSelector((state: any) => state.global.userEmail);
  const bookingsFilters = useSelector((state: any) => state.global.bookingsFilters);
  const enquiriesFilters = useSelector((state: any) => state.global.enquiriesFilters);

  const filterOptions: string[] = type === FilterType.Bookings ? BookingFilterLabels : EnquiryFilterLabels;
  const { data: userPreferenceArr } = useGetUserPreferenceQuery();
  const availableEmails = React.useMemo(() => {
    return userPreferenceArr?.map(pref => pref.email) || [];
  }, [userPreferenceArr]);

  const {
    dateRange,
    startDate,
    endDate,
    bookingStatus,
    bookingTeam,
    consignor,
    consignee,
    carrier,
    portOfLoading,
    portOfDestination,
    containerType,
    salesInvoiceCreated,
    purchaseInvoiceCreated,
    bookingConfirmationSent,
    blStatus,
    outstandingAdditionalCharges,
  } = bookingsFilters;

  const {
    enquiryDateRange,
    enquiryStartDate,
    enquiryEndDate,
    enquiryBookingTeam,
    customer,
    enquiryContainerType,
    enquiryPortOfLoading,
    enquiryPortOfDestination,
    fpod,
    enquiryCarrier,
    enquiryStatus,
    pic,
    incoterm,
  } = enquiriesFilters;

  const { data: customers } = useGetCustomersQuery();
  const { data: vendors } = useGetVendorsQuery();
  const { data: ports } = useGetPortsQuery();

  const defaultBookingFilterValues = {
    // since OnGoing bookingSttaus is pushed by default, safe to put it in array before we sent to multiselect comp
    status: Array.isArray(bookingStatus) ? [...bookingStatus] : [bookingStatus],
    consignors: consignor || [],
    consignees: consignee || [],
    carriers: carrier || [],
    portOfLoadings: portOfLoading || [],
    portOfDestinations: portOfDestination || [],
    containerTypes: containerType || [],
    blStatuses: blStatus || [],
  };
  const defaultEnquiryFilterValues = {
    enquiryStatus: Array.isArray(enquiryStatus) ? [...enquiryStatus] : [enquiryStatus],
    customers: customer || [],
    enquiryCarriers: enquiryCarrier || [],
    enquiryPortOfLoadings: enquiryPortOfLoading || [],
    enquiryPortOfDestinations: enquiryPortOfDestination || [],
    fpods: fpod || [],
    enquiryContainerTypes: enquiryContainerType || [],
    incoterm: incoterm,
    personInCharge: pic,
  };

  const defaultValues = type === FilterType.Bookings ? defaultBookingFilterValues : defaultEnquiryFilterValues;

  const { control, setValue, reset, getValues } = useForm<any>({
    defaultValues,
  });

  useEffect(() => {
    reset(type === FilterType.Bookings ? defaultBookingFilterValues : defaultEnquiryFilterValues);
  }, [bookingsFilters, enquiriesFilters, reset]);

  const handleDateChanges = (newDate: dayjs.Dayjs | null, field: string) => {
    if (newDate && newDate.isValid() && type === FilterType.Bookings) {
      dispatch(setBookingsFilters({ [field]: newDate.toISOString() }));
    } else if (newDate && newDate.isValid() && type === FilterType.Enquiries) {
      dispatch(setEnquiriesFilters({ [field]: newDate.toISOString() }));
    }
  };

  const filterOptionCheck = (label: string) => {
    return filterOptions?.length ? (filterOptions?.includes(label) ? {} : { display: 'none' }) : {};
  };

  const dispatchValues = (e: React.ChangeEvent<HTMLInputElement>, field: string) => {
    dispatch(
      type === FilterType.Bookings
        ? setBookingsFilters({ [field]: e.target.value })
        : setEnquiriesFilters({ [field]: e.target.value }),
    );
  };

  useEffect(() => {
    if (dateRange && type === FilterType.Bookings) {
      dispatch(setBookingsFilters({ startDate: dayjs(endDate).subtract(parseInt(dateRange), 'd').toISOString() }));
    }
    if (enquiryDateRange && type === FilterType.Enquiries) {
      dispatch(
        setEnquiriesFilters({
          enquiryStartDate: dayjs(enquiryEndDate).subtract(parseInt(enquiryDateRange), 'd').toISOString(),
        }),
      );
    }
  }, [dateRange, enquiryDateRange]);

  return (
    <Drawer anchor='right' open={open} onClose={onClose}>
      <Box display='flex' flexDirection='column' gap='1rem' p='1rem' width='300px'>
        <MultiSelectAutoComplete
          control={control}
          name={type === FilterType.Bookings ? 'status' : 'enquiryStatus'}
          label='Status'
          sx={filterOptionCheck('Status')}
          options={type === FilterType.Bookings ? bookingStatuses : enquiryStatuses}
          onChange={value => {
            setValue(type === FilterType.Bookings ? 'status' : 'enquiryStatus', value);
            dispatch(
              type === FilterType.Bookings
                ? setBookingsFilters({ bookingStatus: value })
                : setEnquiriesFilters({ enquiryStatus: value }),
            );
          }}
        />
        <TextField
          select
          label='Team'
          sx={filterOptionCheck('Team')}
          value={type === FilterType.Bookings ? bookingTeam : enquiryBookingTeam}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            dispatchValues(e, type === FilterType.Bookings ? 'bookingTeam' : 'enquiryBookingTeam')
          }
        >
          {generateMenuItemsFromArray(teams ?? [])}
        </TextField>
        <TextField
          label='Date Range (Days)'
          type='number'
          autoComplete='off'
          value={(type === FilterType.Bookings ? dateRange : enquiryDateRange) ?? 30}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            dispatchValues(e, type === FilterType.Bookings ? 'dateRange' : 'enquiryDateRange');
          }}
        />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label={type === FilterType.Bookings ? 'Creation Start' : 'Enquiry Start'}
            format='DD/MM/YYYY'
            value={dayjs(type === FilterType.Bookings ? startDate : enquiryStartDate)}
            onChange={e => handleDateChanges(e, type === FilterType.Bookings ? 'startDate' : 'enquiryStartDate')}
          />
          <DatePicker
            label={type === FilterType.Bookings ? 'Creation End' : 'Enquiry End'}
            format='DD/MM/YYYY'
            value={dayjs(type === FilterType.Bookings ? endDate : enquiryEndDate)}
            onChange={e => handleDateChanges(e, type === FilterType.Bookings ? 'endDate' : 'enquiryEndDate')}
          />
        </LocalizationProvider>
        {/* consignor */}
        <MultiSelectAutoComplete
          control={control}
          name='consignors'
          label='Consignors'
          sx={filterOptionCheck('Consignors')}
          options={customers || []}
          onChange={value => {
            setValue('consignors', value);
            dispatch(setBookingsFilters({ consignor: value }));
          }}
        />

        {/* consignee */}
        <MultiSelectAutoComplete
          control={control}
          name='consignees'
          label='Consignee'
          sx={filterOptionCheck('Consignee')}
          options={customers || []}
          onChange={value => {
            setValue('consignees', value);
            dispatch(setBookingsFilters({ consignee: value }));
          }}
        />
        {/* carrier */}
        <MultiSelectAutoComplete
          control={control}
          name={type === FilterType.Bookings ? 'carriers' : 'enquiryCarriers'}
          label='Carrier'
          sx={filterOptionCheck('Carrier')}
          options={(vendors || []).filter(vendor => vendor.vendorType === VendorType.Carrier)}
          onChange={value => {
            setValue(type === FilterType.Bookings ? 'carriers' : 'enquiryCarriers', value);
            dispatch(
              type === FilterType.Bookings
                ? setBookingsFilters({ carrier: value })
                : setEnquiriesFilters({ enquiryCarrier: value }),
            );
          }}
        />
        {/* port of loading */}
        <MultiSelectAutoComplete
          control={control}
          name={type === FilterType.Bookings ? 'portOfLoadings' : 'enquiryPortOfLoadings'}
          label='POL'
          sx={filterOptionCheck('POL')}
          options={ports || []}
          onChange={value => {
            setValue(type === FilterType.Bookings ? 'portOfLoadings' : 'enquiryPortOfLoadings', value);
            dispatch(
              type === FilterType.Bookings
                ? setBookingsFilters({ portOfLoading: value })
                : setEnquiriesFilters({ enquiryPortOfLoading: value }),
            );
          }}
        />
        {/* port of destination */}
        <MultiSelectAutoComplete
          control={control}
          name={type === FilterType.Bookings ? 'portOfDestinations' : 'enquiryPortOfDestinations'}
          label='POD'
          sx={filterOptionCheck('POD')}
          options={ports || []}
          onChange={value => {
            setValue(type === FilterType.Bookings ? 'portOfDestinations' : 'enquiryPortOfDestinations', value);
            dispatch(
              type === FilterType.Bookings
                ? setBookingsFilters({ portOfDestination: value })
                : setEnquiriesFilters({ enquiryPortOfDestination: value }),
            );
          }}
        />
        {/* fpod */}
        <MultiSelectAutoComplete
          control={control}
          name='fpods'
          label='FPOD'
          sx={filterOptionCheck('FPOD')}
          options={ports || []}
          onChange={value => {
            setValue('fpods', value);
            dispatch(setEnquiriesFilters({ fpod: value }));
          }}
        />
        {/* container type */}
        <MultiSelectAutoComplete
          control={control}
          name={type === FilterType.Bookings ? 'containerTypes' : 'enquiryContainerTypes'}
          label='Container Type'
          sx={filterOptionCheck('Container Type')}
          options={containerTypes || []}
          onChange={value => {
            setValue(type === FilterType.Bookings ? 'containerTypes' : 'enquiryContainerTypes', value);
            dispatch(
              type === FilterType.Bookings
                ? setBookingsFilters({ containerType: value })
                : setEnquiriesFilters({ enquiryContainerType: value }),
            );
          }}
        />
        {/* bl status */}
        <MultiSelectAutoComplete
          control={control}
          name='blStatuses'
          label='BL Status'
          sx={filterOptionCheck('BL Status')}
          options={blStatuses || []}
          onChange={value => {
            setValue('blStatuses', value);
            dispatch(setBookingsFilters({ blStatus: value }));
          }}
        />

        <Autocomplete
          value={salesInvoiceCreated ? salesInvoiceCreated : null}
          options={['Yes', 'No']}
          sx={filterOptionCheck('Sales Invoice Created')}
          isOptionEqualToValue={(option, value) => option === value}
          onChange={(_, value) => {
            if (value) {
              dispatch(setBookingsFilters({ salesInvoiceCreated: value }));
            } else {
              dispatch(setBookingsFilters({ salesInvoiceCreated: undefined }));
            }
          }}
          renderInput={params => <TextField {...params} label='Sales Invoice Created' />}
        />
        <Autocomplete
          value={purchaseInvoiceCreated ? purchaseInvoiceCreated : null}
          options={['Yes', 'No']}
          sx={filterOptionCheck('Purchase Invoice Created')}
          isOptionEqualToValue={(option, value) => option === value}
          onChange={(_, value) => {
            if (value) {
              dispatch(setBookingsFilters({ purchaseInvoiceCreated: value }));
            } else {
              dispatch(setBookingsFilters({ purchaseInvoiceCreated: undefined }));
            }
          }}
          renderInput={params => <TextField {...params} label='Purchase Invoice Created' />}
        />

        <Autocomplete
          value={outstandingAdditionalCharges ? outstandingAdditionalCharges : null}
          options={['Yes', 'No']}
          sx={filterOptionCheck('Outstanding Additional Charges')}
          isOptionEqualToValue={(option, value) => option === value}
          onChange={(_, value) => {
            if (value) {
              dispatch(setBookingsFilters({ outstandingAdditionalCharges: value }));
            } else {
              dispatch(setBookingsFilters({ outstandingAdditionalCharges: undefined }));
            }
          }}
          renderInput={params => <TextField {...params} label='Outstanding Additional Charges' />}
        />

        <Autocomplete
          value={bookingConfirmationSent ? bookingConfirmationSent : null}
          options={['Yes', 'No']}
          sx={filterOptionCheck('Booking Confirmation Sent')}
          isOptionEqualToValue={(option, value) => option === value}
          onChange={(_, value) => {
            if (value) {
              dispatch(setBookingsFilters({ bookingConfirmationSent: value }));
            } else {
              dispatch(setBookingsFilters({ bookingConfirmationSent: undefined }));
            }
          }}
          renderInput={params => <TextField {...params} label='Booking Confirmation Sent' />}
        />
        {/* enquiry */}

        <MultiSelectAutoComplete
          control={control}
          name='customers'
          label='Customer'
          sx={filterOptionCheck('Customer')}
          options={customers || []}
          onChange={value => {
            setValue('customers', value);
            dispatch(setEnquiriesFilters({ customer: value }));
          }}
        />

        <MultiSelectAutoComplete
          control={control}
          name='personInCharge'
          label='Person In Charge'
          sx={filterOptionCheck('Person In Charge')}
          options={availableEmails || []}
          onChange={value => {
            setValue('personInCharge', value);
            dispatch(setEnquiriesFilters({ pic: value }));
          }}
        />
        <MultiSelectAutoComplete
          control={control}
          name='incoterm'
          label='Incoterm'
          sx={filterOptionCheck('Incoterm')}
          options={incoterms || []}
          onChange={value => {
            setValue('incoterm', value);
            dispatch(setEnquiriesFilters({ incoterm: value }));
          }}
        />

        <Button
          color='error'
          startIcon={<ClearOutlined />}
          onClick={() => {
            dispatch(clearBookingsFilters());
            dispatch(clearEnquiriesFilters());
          }}
        >
          Clear Filters
        </Button>
      </Box>
    </Drawer>
  );
};

export default Sidebar;
