import React from 'react';
import { Typography, Box, useTheme } from '@mui/material';

export interface HeaderProps {
  title: string;
  subtitle?: string;
}

const Header: React.FC<HeaderProps> = ({ title, subtitle }) => {
  const theme = useTheme();
  return (
    <Box>
      <Typography
        sx={{
          mb: '5px',
          fontSize: '1.8rem',
          fontWeight: 500,
          textTransform: 'uppercase',
          letterSpacing: '1px',
          color: theme.palette.secondary[400],
        }}
      >
        {title}
      </Typography>
      {subtitle && (
        <Typography variant='h5' color={'#F6F6F6'}>
          {subtitle}
        </Typography>
      )}
    </Box>
  );
};

export default Header;
