import { DeleteOutlined, EditOutlined, RecentActorsOutlined } from '@mui/icons-material';
import { Box, IconButton, Tooltip, useTheme } from '@mui/material';
import { GridCellParams } from '@mui/x-data-grid';
import ConfirmDialogue from 'components/ConfirmDialogue';
import React from 'react';

export function generateActionsColumn<T>(
  hasUpdatePermission: boolean,
  hasDeletePermission: boolean,
  handleClickOpenEdit: (masterData: T) => void,
  setOpenAuditDialogue: React.Dispatch<React.SetStateAction<boolean>>,
  deleteFunction: (id: string) => void,
  openConfirmDialogue: boolean,
  setOpenConfirmDialogue: React.Dispatch<React.SetStateAction<boolean>>,
  currentMasterDataId: string,
  setCurrentMasterDataId: React.Dispatch<React.SetStateAction<string>>,
) {
  const theme = useTheme();
  return {
    field: 'Actions',
    headerName: 'Actions',
    flex: 0.5,
    renderCell: (cellValues: GridCellParams) => {
      return (
        <Box display={'flex'} justifyContent={'space-around'}>
          {hasUpdatePermission && (
            <Tooltip title='Edit'>
              <IconButton
                sx={{ color: theme.palette.secondary[400] }}
                onClick={() => {
                  handleClickOpenEdit(cellValues.row);
                }}
              >
                <EditOutlined />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title='Audit Logs'>
            <IconButton
              sx={{ color: theme.palette.secondary[300] }}
              onClick={() => {
                setCurrentMasterDataId(cellValues.row._id as string);
                setOpenAuditDialogue(true);
              }}
            >
              <RecentActorsOutlined />
            </IconButton>
          </Tooltip>
          {hasDeletePermission && (
            <>
              <Tooltip title='Delete'>
                <IconButton
                  color='error'
                  onClick={() => {
                    setCurrentMasterDataId(cellValues.row._id as string);
                    setOpenConfirmDialogue(true);
                  }}
                >
                  <DeleteOutlined />
                </IconButton>
              </Tooltip>
              <ConfirmDialogue
                open={openConfirmDialogue}
                handleClose={() => setOpenConfirmDialogue(false)}
                title='Delete'
                content='Are you sure you want to delete this item?'
                onConfirm={() => {
                  deleteFunction(currentMasterDataId);
                  setOpenConfirmDialogue(false);
                }}
              />
            </>
          )}
        </Box>
      );
    },
  };
}
