import { AddOutlined, FilterAltOutlined, RecentActorsOutlined, RefreshOutlined } from '@mui/icons-material';
import { Box, Button, IconButton, Tooltip, useTheme } from '@mui/material';
import { GridColDef, GridColumnVisibilityModel } from '@mui/x-data-grid';
import AuditLogsDialogue from 'components/AuditLogsDialogue';
import CreateAdvancePaymentDialog from 'components/CreateAdvancePaymentDialog';
import CustomDataGrid from 'components/CustomDataGrid';
import InvoicesFilterSideBar from 'components/InvoicesFilterSideBar';
import { currencySymbols } from 'dataAssets/constants';
import dayjs from 'dayjs';
import { Customer, IAdvpayments, IBankAccounts, Vendor } from 'models/index.model';
import { default as React, useEffect, useReducer, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useGetAdvancePaymentsQuery, useGetAuditsQuery } from 'state/api';
import { initialState, reducer } from '../../utils/dialogState';
import { useSelector } from 'react-redux';

interface AdvanceComponentProps {
  type: string;
}

const AdvancePayments: React.FC<AdvanceComponentProps> = ({ type }) => {
  const theme = useTheme();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [{ open, entityToUpdate }, dispatchAction] = useReducer(reducer<IAdvpayments>, initialState<IAdvpayments>());
  const [receiptsAndPaymentsData, setReceiptsAndPaymentsData] = useState<IAdvpayments>({} as IAdvpayments);
  const [openAuditLogs, setOpenAuditLogs] = useState(false);
  const { data: receiptsAndPaymentsAuditLogs } = useGetAuditsQuery([
    'ReceiptsAndPayments',
    receiptsAndPaymentsData?._id,
  ]);
  const { parties, startDate, endDate, bankAccounts, invoiceOutstanding } = useSelector(
    (state: any) => state.global.accountingsFilters,
  );

  const {
    data: advancePayments,
    isLoading,
    refetch,
    isFetching,
  } = useGetAdvancePaymentsQuery({
    parties: parties?.map((party: Customer | Vendor) => party._id).join(',') ?? '',
    dateRange: [startDate, endDate],
    bankAccounts: bankAccounts?.map((party: IBankAccounts) => party._id).join(',') ?? '',
    settlementOutstanding: invoiceOutstanding,
  });

  useEffect(() => {
    refetch();
  }, []);

  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
    receiptsAndPaymentsNumber: true,
    party: true,
    dateCreated: true,
    accounts: true,
    adjustments: true,
    totalAmount: true,
    outstandingAmount: true,
  });

  const defaultValues = {
    amount: undefined,
    dateCreated: undefined,
    currency: undefined,
    bankAccount: undefined,
    party: undefined,
    remarks: undefined,
  };

  const currentForm = useForm<IAdvpayments>({
    defaultValues: defaultValues,
  });

  const { reset } = currentForm;

  const handleClickOpenCreate = () => {
    dispatchAction({ type: 'OPEN_DIALOG', payload: {} as IAdvpayments });
  };

  const handleClickOpenEdit = (receiptsAndPayments: IAdvpayments) => {
    dispatchAction({ type: 'OPEN_DIALOG', payload: receiptsAndPayments as IAdvpayments });
  };

  const handleClose = () => {
    dispatchAction({ type: 'CLOSE_DIALOG' });
    reset(defaultValues);
    refetch();
  };

  const columns: GridColDef[] = [
    {
      field: 'advPaymentNumber',
      headerName: 'Adv Payment Number',
      flex: 0.4,
      valueGetter: params => {
        return params.row.advPaymentNumber;
      },
    },
    {
      field: 'party',
      headerName: 'Party',
      flex: 0.3,
      valueGetter: params => {
        return params.row.party?.name;
      },
    },
    {
      field: 'dateCreated',
      headerName: 'Date Created',
      flex: 0.3,
      valueGetter: params => {
        return dayjs(params.row.dateCreated).format('DD/MM/YYYY');
      },
    },
    {
      field: 'bank',
      headerName: 'Bank',
      flex: 0.3,
      valueGetter: params => {
        return params.row.bankAccount.bank;
      },
    },
    {
      field: 'accountNumber',
      headerName: 'A/C No.',
      flex: 0.3,
      valueGetter: params => {
        return params.row.bankAccount.accountNumber;
      },
    },

    {
      field: 'amount',
      headerName: 'Amount',
      flex: 0.3,
      valueGetter: params => {
        return `${currencySymbols[params.row.currency]} ${params.row.amount}`;
      },
    },
    {
      field: 'outstandingAmount',
      headerName: 'Outstanding Amount',
      flex: 0.3,
      valueGetter: params => {
        return `${currencySymbols[params.row.currency]} ${params.row.outstandingAmount}`;
      },
    },
    {
      field: 'settlementOutstanding',
      headerName: 'Settled',
      flex: 0.3,
      valueGetter: params => {
        return params.row.settlementOutstanding ? `No` : 'Yes';
      },
    },
    {
      field: 'Actions',
      flex: 0.4,
      renderCell: (cellValues: any) => {
        return (
          <Box display={'flex'} justifyContent={'space-evenly'}>
            <Box>
              <Tooltip title='View Audit Logs'>
                <IconButton
                  sx={{ color: theme.palette.secondary[400] }}
                  onClick={() => {
                    setReceiptsAndPaymentsData(cellValues.row);
                    setOpenAuditLogs(true);
                  }}
                >
                  <RecentActorsOutlined />
                </IconButton>
              </Tooltip>
            </Box>
            <Box>
              {/* <Tooltip title='Edit Invoice'>
                <IconButton
                  sx={{ color: theme.palette.secondary[400] }}
                  onClick={() => handleClickOpenEdit(cellValues.row)}
                >
                  <EditOutlined />
                </IconButton>
              </Tooltip> */}
            </Box>
          </Box>
        );
      },
    },
  ];

  const handleColumnVisibilityChange = (newModel: GridColumnVisibilityModel) => {
    setColumnVisibilityModel(newModel);
  };

  const iconButtonStyling = {
    color: theme.palette.secondary[400],
    '&:hover': {
      color: theme.palette.secondary[500],
    },
  };

  return (
    <Box mt='1rem'>
      <Box>
        <Box display={'flex'} justifyContent={'flex-end'} alignItems={'center'}>
          <Box>
            <Tooltip title='Create Advance Payments'>
              <IconButton sx={iconButtonStyling} onClick={handleClickOpenCreate}>
                <AddOutlined />
              </IconButton>
            </Tooltip>
            <Tooltip title='Refresh'>
              <IconButton onClick={() => refetch()}>
                <RefreshOutlined />
              </IconButton>
            </Tooltip>
            <Tooltip title='Filter'>
              <IconButton onClick={() => setDrawerOpen(true)}>
                <FilterAltOutlined />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </Box>
      <CustomDataGrid
        data={advancePayments}
        columns={columns}
        isLoading={isLoading}
        isFetching={isFetching}
        columnVisibilityModel={columnVisibilityModel}
        handleColumnVisibilityChange={handleColumnVisibilityChange}
      />
      <CreateAdvancePaymentDialog
        useFormReference={currentForm}
        defaultValues={defaultValues}
        handleClose={handleClose}
        open={open}
        entityToUpdate={entityToUpdate}
      />
      <AuditLogsDialogue
        open={openAuditLogs}
        handleClose={() => setOpenAuditLogs(false)}
        auditLogs={receiptsAndPaymentsAuditLogs}
      />
      <InvoicesFilterSideBar open={drawerOpen} onClose={() => setDrawerOpen(false)} teams={[]} accountsType={type} />
    </Box>
  );
};

export default AdvancePayments;
