import { Autocomplete, Box, Dialog, DialogContent, DialogTitle, TextField, useTheme } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import AlertSnackbar from 'components/AlertSnackbar';
import GeneralDialogueActions from 'components/GeneralDialogueActions';
import dayjs from 'dayjs';
import { BaseDialogueProps, Customer, IAdvpayments, IBankAccounts, Vendor } from 'models/index.model';
import React, { useEffect, useState } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { setSnackbarSettings } from 'state';
import { useGetBankAccountsQuery, useGetCustomersQuery, useGetVendorsQuery } from 'state/api';
import { apiCall } from 'utils/serverFunctions';
import { getValueAndLabelFromBankAccounts, getValueAndLabelFromCustomer } from 'utils/utils';

interface CreateAdvancePaymentDialogProps extends BaseDialogueProps {
  entityToUpdate?: IAdvpayments;
  useFormReference: UseFormReturn<IAdvpayments>;
  defaultValues: any;
}

const CreateAdvancePaymentDialog: React.FC<CreateAdvancePaymentDialogProps> = ({
  handleClose,
  open,
  entityToUpdate,
  useFormReference,
  defaultValues,
}) => {
  const theme = useTheme();
  const isEditing = !!entityToUpdate?._id;
  const {
    register,
    control,
    handleSubmit,
    setValue,
    watch,
    reset,
    getValues,
    formState: { errors },
  } = useFormReference;

  const dispatch = useDispatch();
  const selectedBankAccount = watch('bankAccount');

  useEffect(() => {
    if (selectedBankAccount) {
      setValue('currency', selectedBankAccount.currency);
    }
  }, [selectedBankAccount]);

  useEffect(() => {
    if (entityToUpdate?._id) {
      reset(entityToUpdate);
    } else {
      reset(defaultValues);
    }
  }, [reset, entityToUpdate]);

  const { data: customers } = useGetCustomersQuery();
  const { data: vendors } = useGetVendorsQuery();
  const { data: bankAccounts } = useGetBankAccountsQuery();
  const AllParties = [...(customers || []), ...(vendors || [])];
  const { snackbarOpen, snackbarSeverity, message } = useSelector((state: any) => state.global.snackbarSettings);

  const onSubmit = async (data: IAdvpayments) => {
    try {
      if (isEditing) {
        await apiCall(`/api/v1/advancepayments/${entityToUpdate._id}`, {
          method: 'PATCH',
          data,
        });
      } else {
        await apiCall(`/api/v1/advancepayments/`, {
          method: 'POST',
          data,
        });
      }
      reset(defaultValues);
      dispatch(
        setSnackbarSettings({
          snackbarOpen: true,
          snackbarSeverity: 'success',
          message: isEditing ? `Advance payment updated` : `Advance Payment Created`,
        }),
      );
      handleClose();
    } catch (err) {
      console.log(err);
    }
  };

  const handleSnackbarClose = (event: any, reason: string) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(setSnackbarSettings({ snackbarOpen: false }));
  };
  return (
    <Box>
      <Dialog open={open} onClose={() => handleClose()} fullWidth maxWidth='sm'>
        <DialogTitle
          sx={{
            fontSize: '1.6rem',
          }}
        >
          {isEditing ? `Edit Advance paymentt` : `Create Advance Payment`}
        </DialogTitle>
        <DialogContent>
          <form>
            <Box display={'flex'} flexDirection={'column'} gap={'1rem'} sx={{ paddingTop: '10px' }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Controller
                  control={control}
                  name={'dateCreated'}
                  rules={{ required: `Date of payment is required` }}
                  render={({ field: { onChange, value, ref } }) => (
                    <DatePicker
                      format='DD/MM/YYYY'
                      label={`Date of creation *`}
                      value={value ? dayjs(value) : null}
                      onChange={date => onChange(dayjs(date).format())}
                      inputRef={ref}
                    />
                  )}
                />
              </LocalizationProvider>
              <Controller
                control={control}
                name={'party'}
                rules={{ required: `party is required` }}
                render={({ field: { value, onChange } }) => (
                  <Autocomplete
                    value={value ? getValueAndLabelFromCustomer(value) : null}
                    onChange={(_, value) => {
                      const newParty = AllParties?.find(allParty => allParty._id === value?.value);
                      if (newParty) {
                        onChange(newParty);
                      }
                    }}
                    options={(AllParties ?? []).map((allParty: Customer | Vendor) =>
                      getValueAndLabelFromCustomer(allParty),
                    )}
                    isOptionEqualToValue={(option, value) => option.label === value.label}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label={` Party *`}
                        error={!!errors.party}
                        helperText={errors.party?.message}
                      />
                    )}
                  />
                )}
              />
              <Controller
                control={control}
                name={`amount`}
                rules={{ required: 'Amount is required' }}
                render={({ field: { value, onChange } }) => (
                  <TextField
                    autoComplete='off'
                    type='number'
                    label='Amount *'
                    value={value || ''}
                    onChange={onChange}
                    error={!!errors.amount}
                    helperText={errors.amount?.message}
                  />
                )}
              />
              <Controller
                control={control}
                name={'bankAccount'}
                rules={{ required: `Bank Account is required` }}
                render={({ field: { value, onChange } }) => (
                  <Autocomplete
                    value={value ? getValueAndLabelFromBankAccounts(value) : null}
                    onChange={(_, value) => {
                      const newAcc = bankAccounts?.find(bankAccount => bankAccount._id === value?.value);
                      if (newAcc) {
                        onChange(newAcc);
                      }
                    }}
                    options={(bankAccounts ?? []).map((bankAccount: IBankAccounts) =>
                      getValueAndLabelFromBankAccounts(bankAccount),
                    )}
                    isOptionEqualToValue={(option, value) => option.label === value.label}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label={`A/C No. *`}
                        error={!!errors.bankAccount}
                        helperText={errors.bankAccount?.message}
                      />
                    )}
                  />
                )}
              />
              <Controller
                control={control}
                name={`currency`}
                render={({ field: { value, onChange } }) => (
                  <TextField
                    autoComplete='off'
                    type='text'
                    label='Currency *'
                    value={selectedBankAccount?.currency || ''}
                    InputProps={{ readOnly: true }}
                  />
                )}
              />
              <Controller
                control={control}
                name={`remarks`}
                render={({ field: { value, onChange } }) => (
                  <TextField autoComplete='off' type='text' label='Remarks' value={value || ''} onChange={onChange} />
                )}
              />
            </Box>
          </form>
        </DialogContent>
        <GeneralDialogueActions
          onClick={handleSubmit(onSubmit)}
          handleClose={handleClose}
          submitText={isEditing ? 'Edit' : 'Create'}
        />
      </Dialog>
      <AlertSnackbar
        open={snackbarOpen}
        handleClose={handleSnackbarClose}
        severity={snackbarSeverity}
        message={message}
      />
    </Box>
  );
};

export default CreateAdvancePaymentDialog;
