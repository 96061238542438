import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Autocomplete, Checkbox, Chip, TextField } from '@mui/material';
import { default as React } from 'react';
import { Controller } from 'react-hook-form';

const checkedIcon = <CheckBoxIcon fontSize='small' />;

interface MultiSelectAutoCompleteProps<T> {
  control: any;
  name: string;
  options: T[];
  label: string;
  onChange: (value: any) => void;
  sx: { display?: string };
}

interface BaseOption {
  name?: string;
  portName?: string;
  accountNumber?: string;
}

const optionNamesToList = (type: string): 'portName' | 'name' | 'accountNumber' => {
  switch (type) {
    case 'POL':
    case 'POD':
    case 'FPOD':
      return 'portName';
    case 'Bank Account':
      return 'accountNumber';
    default:
      return 'name';
  }
};

const getOptionValue = (option: string | BaseOption, label: string): string => {
  if (typeof option === 'string') {
    return option;
  } else if (typeof option === 'object' && option !== null) {
    const property = optionNamesToList(label);
    return option[property] ?? '';
  }
  return '';
};

const MultiSelectAutoComplete = <T,>({
  sx,
  control,
  name,
  options,
  label,
  onChange,
}: MultiSelectAutoCompleteProps<T>) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value } }) => (
        <Autocomplete
          multiple
          disableCloseOnSelect
          disablePortal
          sx={sx}
          limitTags={3}
          value={value}
          onChange={(_, newValue) => {
            onChange(newValue);
          }}
          getOptionLabel={(val: any) => getOptionValue(val, label)}
          isOptionEqualToValue={(option, val) => getOptionValue(option, label) === getOptionValue(val, label)}
          options={options ?? []}
          renderOption={(props, option, { selected, index }) => (
            <li key={index} {...props}>
              <Checkbox checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
              {getOptionValue(option, label)}
            </li>
          )}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => {
              const { key, ...tagProps } = getTagProps({ index });
              return (
                <Chip key={key} variant='outlined' label={getOptionValue(option, label)} size='small' {...tagProps} />
              );
            })
          }
          renderInput={params => <TextField {...params} label={label} />}
        />
      )}
    />
  );
};

export default MultiSelectAutoComplete;
