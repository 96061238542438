import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogContent,
  Box,
  Tooltip,
  DialogTitle,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Radio,
  FormLabel,
  IconButton,
} from '@mui/material';
import { MonthlyPortVolumes } from 'models/analytics';
import { BaseDialogueProps } from 'models/index.model';
import GeneralDialogueActions from 'components/GeneralDialogueActions';
import { getAllMonthNames, getCurrentMonth, handleExcelDownload, truncateName } from 'utils/utils';
import { DownloadOutlined } from '@mui/icons-material';

interface MonthlyPortVolumeTableProps extends BaseDialogueProps {
  data: MonthlyPortVolumes[];
  selectedYear: number;
  selectedMonth: number | null;
}

const MonthlyPortVolumeTable: React.FC<MonthlyPortVolumeTableProps> = ({
  data,
  handleClose,
  open,
  selectedYear,
  selectedMonth,
}) => {
  const currentMonth = getCurrentMonth(selectedYear, selectedMonth);
  const allMonths = getAllMonthNames(currentMonth);
  const [volumeType, setVolumeType] = useState<string>('Import');
  const [transformedData, setTransformedData] = useState<{ Port: string; [key: string]: string | number }[]>([]);
  const [excelFileName, setExcelFileName] = useState<string>('Port-Volume-Monthly-Report');

  useEffect(() => {
    const s_excelFileName = selectedMonth
      ? `Port-Volume-Monthly-${volumeType}-Report_${allMonths[selectedMonth - 1]}-${selectedYear.toString()}`
      : `Port-Volume-Monthly-${volumeType}-Report_${selectedYear.toString()}`;

    setExcelFileName(s_excelFileName);
  }, [volumeType, selectedMonth, selectedYear]);

  const handleVolumeTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setVolumeType(event.target.value);
  };

  useEffect(() => {
    const s_transformedData = data.map(item => {
      const volumeColumns: { [key: string]: string } = {};

      allMonths.map((month, index) => {
        if (volumeType === 'Export') {
          volumeColumns[`${month} (Volume)`] = `${item.exportPortVolumes[index].volume}`;
        } else {
          volumeColumns[`${month} (Volume)`] = `${item.importPortVolumes[index].volume}`;
        }
      });

      return {
        Port: item.portCode,
        ...volumeColumns,
        [`Total ${volumeType} Volume`]: volumeType === 'Export' ? item.totalExportVolumes : item.totalImportVolumes,
      };
    });

    setTransformedData(s_transformedData);
  }, [data, volumeType]);

  const [totalExportVolumes, totalImportVolumes] = allMonths
    .map(month => {
      return data.reduce(
        ([exportTotal, importTotal], port) => {
          const exportMonthData = port.exportPortVolumes.find(m => m.month === month);
          const importMonthData = port.importPortVolumes.find(m => m.month === month);

          return [exportTotal + (exportMonthData?.volume || 0), importTotal + (importMonthData?.volume || 0)];
        },
        [0, 0],
      );
    })
    .reduce(
      ([exportVolumes, importVolumes], [exportTotal, importTotal]) => {
        exportVolumes.push(exportTotal);
        importVolumes.push(importTotal);
        return [exportVolumes, importVolumes];
      },
      [[], []] as [number[], number[]],
    );

  const transformedTotalPortVolume: { [key: string]: string } = {};
  allMonths.forEach((month, index) => {
    if (volumeType === 'Export') {
      transformedTotalPortVolume[`${month} (Volume)`] = `${totalExportVolumes[index]}`;
    } else {
      transformedTotalPortVolume[`${month} (Volume)`] = `${totalImportVolumes[index]}`;
    }
  });

  const transformedDataWithTotalPortVolume = [
    ...transformedData,
    {
      Port: 'YTD',
      ...transformedTotalPortVolume,
      [`Total ${volumeType} Volume`]:
        volumeType === 'Export'
          ? `${data.reduce((total, port) => total + port.totalExportVolumes, 0)}`
          : `${data.reduce((total, port) => total + port.totalImportVolumes, 0)}`,
    },
  ];

  return (
    <Box>
      <Dialog open={open} onClose={() => handleClose()} fullWidth fullScreen maxWidth='xl'>
        <DialogTitle>
          <Box display={'flex'} alignItems={'center'} gap={1}>
            {volumeType} Monthly Port Volume Report
            <Tooltip title='Download as Excel' placement='top'>
              <IconButton onClick={() => handleExcelDownload(transformedDataWithTotalPortVolume, excelFileName)}>
                <DownloadOutlined />
              </IconButton>
            </Tooltip>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <FormControl component='fieldset'>
              <Box display='flex' alignItems='center'>
                <FormLabel component='legend' sx={{ marginRight: 2 }}>
                  Volume Type
                </FormLabel>
                <RadioGroup
                  row
                  aria-label='volume-type'
                  name='volume-type'
                  value={volumeType}
                  onChange={handleVolumeTypeChange}
                >
                  <FormControlLabel value='Import' control={<Radio />} label='Import' />
                  <FormControlLabel value='Export' control={<Radio />} label='Export' />
                </RadioGroup>
              </Box>
            </FormControl>
          </Box>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align='center' sx={{ borderRight: '1px solid #515151' }}>
                    Port
                  </TableCell>
                  {allMonths.map(month => (
                    <React.Fragment key={month}>
                      <TableCell align='center' sx={{ borderRight: '1px solid #515151' }}>
                        {month} (Volume)
                      </TableCell>
                    </React.Fragment>
                  ))}
                  <TableCell align='center'>
                    {volumeType === 'Import' ? 'Total Import Volume' : 'Total Export Volume'}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((port, index) => (
                  <TableRow key={index} sx={{ backgroundColor: '#292929' }}>
                    <TableCell align='left' sx={{ borderRight: '1px solid #515151' }}>
                      <Tooltip title={port.portCode} placement='top'>
                        <span>{truncateName(port.portCode)}</span>
                      </Tooltip>
                    </TableCell>
                    {volumeType === 'Import'
                      ? allMonths.map(month => {
                          const monthData = port.importPortVolumes.find(m => m.month === month);
                          return (
                            <React.Fragment key={`${month}-${port.portCode}`}>
                              <TableCell align='center' sx={{ borderRight: '1px solid #515151' }}>
                                {monthData?.volume || 0}
                              </TableCell>
                            </React.Fragment>
                          );
                        })
                      : allMonths.map(month => {
                          const monthData = port.exportPortVolumes.find(m => m.month === month);
                          return (
                            <React.Fragment key={`${month}-${port.portCode}`}>
                              <TableCell align='center' sx={{ borderRight: '1px solid #515151' }}>
                                {monthData?.volume || 0}
                              </TableCell>
                            </React.Fragment>
                          );
                        })}
                    <TableCell align='center'>
                      {volumeType === 'Import'
                        ? (port.totalImportVolumes ?? 'N/A')
                        : (port.totalExportVolumes ?? 'N/A')}
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell sx={{ borderRight: '1px solid #515151' }}>YTD</TableCell>
                  {volumeType === 'Import'
                    ? totalImportVolumes.map((totalImportPortVolume, index) => (
                        <React.Fragment key={index}>
                          <TableCell align='center' sx={{ borderRight: '1px solid #515151' }}>
                            {totalImportPortVolume}
                          </TableCell>
                        </React.Fragment>
                      ))
                    : totalExportVolumes.map((totalExportPortVolume, index) => (
                        <React.Fragment key={index}>
                          <TableCell align='center' sx={{ borderRight: '1px solid #515151' }}>
                            {totalExportPortVolume}
                          </TableCell>
                        </React.Fragment>
                      ))}
                  <TableCell align='center'>
                    {volumeType === 'Import'
                      ? `${data.reduce((total, port) => total + port.totalImportVolumes, 0)}`
                      : `${data.reduce((total, port) => total + port.totalExportVolumes, 0)}`}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <GeneralDialogueActions noSubmission handleClose={handleClose} />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default MonthlyPortVolumeTable;
