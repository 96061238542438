import React, { useState } from 'react';
import { Controller, Control } from 'react-hook-form';
import { apiCall } from 'utils/serverFunctions';
import CreateEditAutocomplete from 'components/CreateEditAutocomplete';
import ChargeDialog from 'components/ChargeDialog';
import { ChargeName } from 'models/index.model';

interface ChargeNameAutocompleteProps {
  control: Control<any>;
  name: string;
  chargeType?: string;
}

const ChargeNameAutocomplete: React.FC<ChargeNameAutocompleteProps> = ({ control, name, chargeType }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedCharge, setSelectedCharge] = useState<ChargeName | null>(null);
  const [initialChargeName, setInitialChargeName] = useState<string | undefined>();

  const fetchCharges = async (): Promise<ChargeName[]> => {
    const response = await apiCall<ChargeName[]>('/api/v1/charges', {
      method: 'GET',
      params: { chargeType },
    });
    return response?.data || [];
  };

  const handleEdit = (charge: ChargeName) => {
    setSelectedCharge(charge);
    setDialogOpen(true);
    setInitialChargeName(undefined); // Clear initialChargeName for editing
  };

  const handleCreate = (newLabel: string) => {
    setSelectedCharge(null);
    setInitialChargeName(newLabel); // Set initialChargeName for new charge
    setDialogOpen(true);
  };

  const handleDialogClose = (updatedCharge: ChargeName | null, onChange: (value: ChargeName | null) => void) => {
    setDialogOpen(false);
    if (updatedCharge) {
      onChange(updatedCharge);
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={null}
      render={({ field: { value, onChange } }) => (
        <>
          <CreateEditAutocomplete<ChargeName>
            fetchOptions={fetchCharges}
            getOptionLabel={option => option.chargeName || ''}
            onEdit={handleEdit}
            onCreate={handleCreate}
            onChange={onChange}
            value={value}
            label='Select or Create Charge'
          />
          {dialogOpen && (
            <ChargeDialog
              open={dialogOpen}
              charge={selectedCharge}
              initialChargeName={initialChargeName}
              onClose={updatedCharge => handleDialogClose(updatedCharge, onChange)}
            />
          )}
        </>
      )}
    />
  );
};

export default ChargeNameAutocomplete;
