import {
  AddOutlined,
  EditOutlined,
  FileDownloadOutlined,
  FilterAltOutlined,
  RecentActorsOutlined,
  RefreshOutlined,
} from '@mui/icons-material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { GridColDef, GridColumnVisibilityModel } from '@mui/x-data-grid';
import AuditLogsDialogue from 'components/AuditLogsDialogue';
import CustomDataGrid from 'components/CustomDataGrid';
import GeneralDialogueActions from 'components/GeneralDialogueActions';
import InvoicesFilterSideBar from 'components/InvoicesFilterSideBar';
import { currencySymbols } from 'dataAssets/constants';
import dayjs from 'dayjs';
import { Customer, IBankAccounts, IReceiptsAndPayments, Vendor } from 'models/index.model';
import { default as React, useReducer, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useGetAuditsQuery, useGetReceiptsAndPaymentsQuery } from 'state/api';
import { setBookingTeams, totalInvoiceValue, useUserPermissions } from 'utils/utils';
import { initialState, reducer } from '../../utils/dialogState';
import CreateReceiptsAndPaymentsDialog from './CreateReceiptsAndPaymentsDialog';

interface IReceiptsAndPaymentsProps {
  type: 'Receipt' | 'Payment';
}

const ReceiptsAndPayments: React.FC<IReceiptsAndPaymentsProps> = ({ type }) => {
  const theme = useTheme();
  const userPermissions = useUserPermissions();
  const hasExportPermission = userPermissions.includes('read:bookings-export');
  const hasImportPermission = userPermissions.includes('read:bookings-import');
  const teams = setBookingTeams(hasExportPermission, hasImportPermission);
  const [{ open, entityToUpdate }, dispatchAction] = useReducer(
    reducer<IReceiptsAndPayments>,
    initialState<IReceiptsAndPayments>(),
  );
  const [receiptsAndPaymentsData, setReceiptsAndPaymentsData] = useState<IReceiptsAndPayments>(
    {} as IReceiptsAndPayments,
  );
  const [dataToDisplay, setDataToDisplay] = useState<IReceiptsAndPayments | null>(null);
  const [openInfoDialogue, setOpenInfoDialogue] = useState(false);
  const [openAuditLogs, setOpenAuditLogs] = useState(false);
  const { parties, startDate, endDate, bankAccounts } = useSelector((state: any) => state.global.accountingsFilters);
  const {
    data: receiptsAndPayments,
    isLoading,
    refetch,
    isFetching,
  } = useGetReceiptsAndPaymentsQuery([
    type,
    {
      parties: parties?.map((party: Customer | Vendor) => party._id).join(',') ?? '',
      dateRange: [startDate, endDate],
      bankAccounts: bankAccounts?.map((party: IBankAccounts) => party._id).join(',') ?? '',
    },
  ]);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const { data: receiptsAndPaymentsAuditLogs } = useGetAuditsQuery([
    'ReceiptsAndPayments',
    receiptsAndPaymentsData?._id,
  ]);

  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
    receiptsAndPaymentsNumber: true,
    party: true,
    dateCreated: true,
    accounts: true,
    currency: true,
    adjustments: true,
    totalAmount: true,
    outstandingAmount: true,
  });

  const defaultValues = {
    party: undefined,
    dateCreated: undefined,
    accounts: undefined,
    invoicesRefs: [],
    advancesRefs: [],
    totalAmount: undefined,
    remarks: undefined,
    currency: undefined,
  };

  const currentForm = useForm<IReceiptsAndPayments>({
    defaultValues: defaultValues,
  });

  const { reset } = currentForm;

  const handleClickOpenCreate = () => {
    dispatchAction({ type: 'OPEN_DIALOG', payload: {} as IReceiptsAndPayments });
  };

  const handleClickOpenEdit = (receiptsAndPayments: IReceiptsAndPayments) => {
    dispatchAction({ type: 'OPEN_DIALOG', payload: receiptsAndPayments as IReceiptsAndPayments });
  };

  const handleClose = () => {
    dispatchAction({ type: 'CLOSE_DIALOG' });
    reset(defaultValues);
    refetch();
  };

  const columns: GridColDef[] = [
    {
      field: 'receiptsAndPaymentsNumber',
      headerName: 'Entity Number',
      flex: 0.4,
      valueGetter: params => {
        return params.row.receiptsAndPaymentsNumber;
      },
    },
    {
      field: 'party',
      headerName: 'Party',
      flex: 0.3,
      valueGetter: params => {
        return params.row.party?.name;
      },
    },
    {
      field: 'dateCreated',
      headerName: 'Date Created',
      flex: 0.5,
      valueGetter: params => {
        return dayjs(params.row.dateCreated).format('DD/MM/YYYY');
      },
    },
    {
      field: 'accounts',
      headerName: 'Bank',
      flex: 0.3,
      valueGetter: params => {
        return params.row.accounts?.bank;
      },
    },
    {
      field: 'accountNumber',
      headerName: 'A/C No.',
      flex: 0.3,
      valueGetter: params => {
        return params.row.accounts?.accountNumber;
      },
    },
    {
      field: 'adjustments',
      headerName: 'Adjustments',
      flex: 0.3,
      renderCell: params => {
        return (
          params.row.invoicesRefs.length && (
            <Box display={'flex'} justifyContent={'space-evenly'}>
              <Box>
                <Tooltip title='View '>
                  <IconButton
                    sx={{ color: theme.palette.secondary[400] }}
                    onClick={() => {
                      setOpenInfoDialogue(true);
                      setDataToDisplay(params.row);
                    }}
                  >
                    <InfoOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          )
        );
      },
    },
    {
      field: 'totalAmount',
      headerName: 'Total Amount',
      flex: 0.3,
      valueGetter: params => {
        return `${currencySymbols[params.row.accounts?.currency]} ${params.row.totalAmount}`;
      },
    },
    {
      field: 'Actions',
      flex: 0.4,
      renderCell: (cellValues: any) => {
        return (
          <Box display={'flex'} justifyContent={'space-evenly'}>
            <Box>
              <Tooltip title='View Audit Logs'>
                <IconButton
                  sx={{ color: theme.palette.secondary[400] }}
                  onClick={() => {
                    setReceiptsAndPaymentsData(cellValues.row);
                    setOpenAuditLogs(true);
                  }}
                >
                  <RecentActorsOutlined />
                </IconButton>
              </Tooltip>
            </Box>
            <Box>
              <Tooltip title='Edit Invoice'>
                <IconButton
                  sx={{ color: theme.palette.secondary[400] }}
                  onClick={() => handleClickOpenEdit(cellValues.row)}
                >
                  <EditOutlined />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        );
      },
    },
  ];

  const handleColumnVisibilityChange = (newModel: GridColumnVisibilityModel) => {
    setColumnVisibilityModel(newModel);
  };

  const exportToExcel = (receiptsAndPayments: IReceiptsAndPayments[]) => {
    console.log(receiptsAndPayments);
  };

  const iconButtonStyling = {
    color: theme.palette.secondary[400],
    '&:hover': {
      color: theme.palette.secondary[500],
    },
  };

  return (
    <Box mt='1rem'>
      <Box>
        <Box display={'flex'} justifyContent={'flex-end'} alignItems={'center'}>
          <Box>
            <Tooltip title={`Create ${type}`}>
              <IconButton sx={iconButtonStyling} onClick={handleClickOpenCreate}>
                <AddOutlined />
              </IconButton>
            </Tooltip>
            <Tooltip title='Refresh'>
              <IconButton onClick={() => refetch()}>
                <RefreshOutlined />
              </IconButton>
            </Tooltip>
            <Tooltip title='Filter'>
              <IconButton onClick={() => setDrawerOpen(true)}>
                <FilterAltOutlined />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </Box>
      <CustomDataGrid
        data={receiptsAndPayments}
        columns={columns}
        isLoading={isLoading}
        isFetching={isFetching}
        columnVisibilityModel={columnVisibilityModel}
        handleColumnVisibilityChange={handleColumnVisibilityChange}
      />
      <CreateReceiptsAndPaymentsDialog
        useFormReference={currentForm}
        defaultValues={defaultValues}
        handleClose={handleClose}
        open={open}
        entityToUpdate={entityToUpdate}
        type={type}
      />
      <AuditLogsDialogue
        open={openAuditLogs}
        handleClose={() => setOpenAuditLogs(false)}
        auditLogs={receiptsAndPaymentsAuditLogs}
      />
      <InvoicesFilterSideBar open={drawerOpen} onClose={() => setDrawerOpen(false)} teams={teams} accountsType={type} />

      <Dialog open={openInfoDialogue} onClose={() => setOpenInfoDialogue(false)} fullWidth maxWidth='lg'>
        {dataToDisplay ? (
          <>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '1rem',
                overflow: 'hidden !important',
              }}
            >
              <Typography
                sx={{
                  fontSize: '1.6rem',
                }}
              >
                Adjustment Details
              </Typography>
              <Typography>
                {currencySymbols[dataToDisplay?.accounts?.currency]} {dataToDisplay?.totalAmount}
              </Typography>
            </Box>
            <DialogContent>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Invoice Number</TableCell>
                      <TableCell>Paid Amount</TableCell>
                      <TableCell>Outstanding Amount</TableCell>
                      <TableCell>Total Invoice Value</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dataToDisplay &&
                      dataToDisplay?.invoicesRefs?.map((data, index) => (
                        <TableRow key={index}>
                          <TableCell>{data.invoiceRef.approvedInvoiceNumber}</TableCell>
                          <TableCell>
                            {currencySymbols[dataToDisplay?.accounts?.currency]} {data.amount}
                          </TableCell>
                          <TableCell>
                            {currencySymbols[dataToDisplay?.accounts?.currency]} {data.invoiceRef.outstandingAmount}
                          </TableCell>
                          <TableCell>
                            {currencySymbols[dataToDisplay?.accounts?.currency]}{' '}
                            {totalInvoiceValue(data.invoiceRef.charges, data.invoiceRef.numberOfContainers)}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </DialogContent>
          </>
        ) : (
          <Typography>No Data Available</Typography>
        )}
        <GeneralDialogueActions noSubmission handleClose={() => setOpenInfoDialogue(false)} />
      </Dialog>
    </Box>
  );
};

export default ReceiptsAndPayments;
