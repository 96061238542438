import { ClearOutlined } from '@mui/icons-material';
import { Box, Button, Drawer, Grid, Switch, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import MultiSelectAutoComplete from 'components/MultiSelectAutoComplete';
import dayjs from 'dayjs';
import { TeamType } from 'models/booking.model';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { clearAccountingsFilters, setAccountingsFilters, setInvoicesFilters } from 'state';
import { useGetBankAccountsQuery, useGetCustomersQuery, useGetVendorsQuery } from 'state/api';
import { generateMenuItemsFromArray } from 'utils/generateMenuItemsFromArray';

interface SidebarProps {
  open: boolean;
  onClose: () => void;
  teams: TeamType[] | undefined;
  accountsType: string;
}

const Sidebar: React.FC<SidebarProps> = ({ open, onClose, teams, accountsType }) => {
  const dispatch = useDispatch();

  const { parties, pendingApproval, invoiceOutstanding, underDispute, startDate, endDate, bookingTeam, bankAccounts } =
    useSelector((state: any) => state.global.accountingsFilters);

  const { control, setValue } = useForm({
    defaultValues: {
      parties: parties,
      bankAccounts: bankAccounts,
    },
  });

  const { data: customers } = useGetCustomersQuery();
  const { data: vendors } = useGetVendorsQuery();
  const { data: bankAccountsData } = useGetBankAccountsQuery();
  const allParties = [...(customers || []), ...(vendors || [])];

  useEffect(() => {
    dispatch(setAccountingsFilters({ accountsType: accountsType }));
    if (accountsType === 'Advance') {
      dispatch(setAccountingsFilters({ pendingApproval: false }));
    }
  }, [accountsType]);

  const handlePendingApprovalToggle = () => {
    dispatch(setAccountingsFilters({ pendingApproval: !pendingApproval }));
    dispatch(
      setAccountingsFilters({
        dateType: pendingApproval ? 'approvalDate' : 'invoiceDate',
      }),
    );
    dispatch(setInvoicesFilters({ underDispute: false }));
  };

  const handleOutstandingToggle = () => {
    dispatch(setAccountingsFilters({ invoiceOutstanding: !invoiceOutstanding }));
  };

  const handleUnderDisputeToggle = () => {
    dispatch(setAccountingsFilters({ underDispute: !underDispute }));
  };

  const handleStartDateChange = (newDate: dayjs.Dayjs | null) => {
    if (newDate && newDate.isValid()) {
      dispatch(setAccountingsFilters({ startDate: newDate.toISOString() }));
    }
  };

  const handleEndDateChange = (newDate: dayjs.Dayjs | null) => {
    if (newDate && newDate.isValid()) {
      dispatch(setAccountingsFilters({ endDate: newDate.toISOString() }));
    }
  };
  const handleBookingTeamChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setAccountingsFilters({ bookingTeam: e.target.value }));
  };

  return (
    <Drawer anchor='right' open={open} onClose={onClose}>
      <Box display='flex' flexDirection='column' gap='1rem' p='1rem' width='300px'>
        <MultiSelectAutoComplete
          control={control}
          name='parties'
          sx={{}}
          label='Party'
          options={allParties || []}
          onChange={value => {
            setValue('parties', value);
            dispatch(setAccountingsFilters({ parties: value }));
          }}
        />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label={`${pendingApproval ? 'Creation' : 'Approval'} Start`}
            format='DD/MM/YYYY'
            value={dayjs(startDate)}
            onChange={handleStartDateChange}
          />
          <DatePicker
            label={`${pendingApproval ? 'Creation' : 'Approval'} End`}
            format='DD/MM/YYYY'
            value={dayjs(endDate)}
            onChange={handleEndDateChange}
          />
        </LocalizationProvider>

        <TextField
          select
          label='Team'
          sx={{ display: ['Receipt', 'Payment', 'Advance'].includes(accountsType) ? 'none' : 'inherit' }}
          value={bookingTeam}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleBookingTeamChange(e)}
        >
          {generateMenuItemsFromArray(teams ?? [])}
        </TextField>
        <MultiSelectAutoComplete
          control={control}
          name='bankAccounts'
          sx={{ display: !['Receipt', 'Payment', 'Advance'].includes(accountsType) ? 'none' : 'inherit' }}
          label='Bank Account'
          options={bankAccountsData || []}
          onChange={value => {
            setValue('bankAccounts', value);
            dispatch(setAccountingsFilters({ bankAccounts: value }));
          }}
        />
        <Box>
          {!['Receipt', 'Payment'].includes(accountsType) && (
            <>
              {accountsType !== 'Advance' && (
                <>
                  <Grid component='label' container alignItems='center' spacing={1}>
                    <Grid item sx={{ minWidth: '95px', opacity: pendingApproval ? '0.3' : 'none' }}>
                      Approved
                    </Grid>
                    <Grid item>
                      <Switch checked={pendingApproval} onChange={handlePendingApprovalToggle} />
                    </Grid>
                    <Grid item sx={{ minWidth: '95px', opacity: !pendingApproval ? '0.3' : 'none' }}>
                      Unapproved
                    </Grid>
                  </Grid>
                  <Grid component='label' container alignItems='center' spacing={1}>
                    <Grid
                      item
                      sx={{ minWidth: '95px', opacity: !pendingApproval ? '0.3' : underDispute ? '0.3' : 'none' }}
                    >
                      No Dispute
                    </Grid>
                    <Grid item>
                      <Switch checked={underDispute} onChange={handleUnderDisputeToggle} disabled={!pendingApproval} />
                    </Grid>
                    <Grid
                      item
                      sx={{ minWidth: '95px', opacity: !pendingApproval ? '0.3' : !underDispute ? '0.3' : 'none' }}
                    >
                      Under Dispute
                    </Grid>
                  </Grid>
                </>
              )}
              <Grid component='label' container alignItems='center' spacing={1}>
                <Grid
                  item
                  sx={{ minWidth: '95px', opacity: pendingApproval ? '0.3' : invoiceOutstanding ? '0.3' : 'none' }}
                >
                  Settled
                </Grid>
                <Grid item>
                  <Switch checked={invoiceOutstanding} onChange={handleOutstandingToggle} disabled={pendingApproval} />
                </Grid>
                <Grid
                  item
                  sx={{ minWidth: '95px', opacity: pendingApproval ? '0.3' : !invoiceOutstanding ? '0.3' : 'none' }}
                >
                  Outstanding
                </Grid>
              </Grid>
            </>
          )}
        </Box>
        <Button
          color='error'
          startIcon={<ClearOutlined />}
          onClick={() => {
            dispatch(clearAccountingsFilters());
          }}
        >
          Clear Filters
        </Button>
      </Box>
    </Drawer>
  );
};

export default Sidebar;
