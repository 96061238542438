import React from 'react';
import { Box, Dialog, DialogContent, DialogTitle, useTheme } from '@mui/material';
import { CustomerVolumeProfitByMonth } from 'models/analytics';
import { BaseDialogueProps } from 'models/index.model';
import GeneralDialogueActions from 'components/GeneralDialogueActions';
import { Area, AreaChart, Label, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import AnalyticsCardHeader from 'components/AnalyticsCardHeader';

interface CustomerMonthlyStatsProps extends BaseDialogueProps {
  customerData: CustomerVolumeProfitByMonth;
}

const CustomerMonthlyStats: React.FC<CustomerMonthlyStatsProps> = ({ customerData, handleClose, open }) => {
  const theme = useTheme();

  const data = customerData?.monthlyVolumesProfits?.map(entry => ({
    month: entry.month,
    volume: entry.volume,
    profit: entry.profit,
  }));

  return (
    <Box>
      <Dialog open={open} onClose={() => handleClose()} fullWidth maxWidth='xl'>
        <DialogTitle>{customerData.customerName}</DialogTitle>
        <DialogContent>
          <Box>
            <AnalyticsCardHeader title='Total Monthly Volumes' />
            <ResponsiveContainer width='100%' height={300}>
              <AreaChart
                width={500}
                height={400}
                data={data}
                margin={{
                  right: 30,
                }}
              >
                <defs>
                  <linearGradient id='volumeGradient' x1='0' y1='0' x2='0' y2='1'>
                    <stop offset='5%' stopColor={theme.palette.primary[300]} stopOpacity={0.5} />
                    <stop offset='95%' stopColor={theme.palette.primary[300]} stopOpacity={0} />
                  </linearGradient>
                </defs>
                <XAxis dataKey='month' tickLine={false} style={{ fontSize: '10px' }} />
                <YAxis tickLine={false} axisLine={{ strokeWidth: '0' }} style={{ fontSize: '10px' }} />
                <Tooltip
                  cursor={false}
                  contentStyle={{
                    backgroundColor: theme.palette.background.alt,
                  }}
                  labelStyle={{ color: theme.palette.text.primary }}
                  itemStyle={{ color: theme.palette.text.primary }}
                />
                <Area
                  type='monotone'
                  dataKey='volume'
                  dot={true}
                  stroke={theme.palette.primary.main}
                  fillOpacity={1}
                  fill='url(#volumeGradient)'
                />
              </AreaChart>
            </ResponsiveContainer>
            <AnalyticsCardHeader title='Total Monthly Profits' />
            <ResponsiveContainer width='100%' height={300}>
              <LineChart
                width={500}
                height={400}
                data={data}
                margin={{
                  right: 30,
                }}
              >
                <defs>
                  <linearGradient id='profitGradient' x1='0' y1='0' x2='0' y2='1'>
                    <stop offset='5%' stopColor={theme.palette.secondary[300]} stopOpacity={0.5} />
                    <stop offset='95%' stopColor={theme.palette.secondary[300]} stopOpacity={0} />
                  </linearGradient>
                </defs>
                <XAxis dataKey='month' tickLine={false} style={{ fontSize: '10px' }} />
                <YAxis tickLine={false} axisLine={{ strokeWidth: '0' }} style={{ fontSize: '10px' }}>
                  <Label value='£' position='insideLeft' style={{ textAnchor: 'middle' }} />
                </YAxis>
                <Tooltip
                  cursor={false}
                  contentStyle={{
                    backgroundColor: theme.palette.background.alt,
                  }}
                  labelStyle={{ color: theme.palette.text.primary }}
                  itemStyle={{ color: theme.palette.text.primary }}
                />
                <Line type='monotone' dataKey='profit' stroke={theme.palette.secondary.main} />
              </LineChart>
            </ResponsiveContainer>
          </Box>
          <GeneralDialogueActions noSubmission handleClose={handleClose} />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default CustomerMonthlyStats;
