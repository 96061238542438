import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogContent,
  Box,
  Tooltip,
  DialogTitle,
  IconButton,
} from '@mui/material';
import { CustomerVolumeProfitByMonth } from 'models/analytics';
import { BaseDialogueProps } from 'models/index.model';
import GeneralDialogueActions from 'components/GeneralDialogueActions';
import { getAllMonthNames, getCurrentMonth, handleExcelDownload, truncateName } from 'utils/utils';
import { DownloadOutlined } from '@mui/icons-material';

interface CustomerVolumeAndProfitMonthlyReportTableProps extends BaseDialogueProps {
  data: CustomerVolumeProfitByMonth[];
  profitType: string;
  selectedYear: number;
  selectedMonth: number | null;
}

const CustomerVolumeAndProfitMonthlyReportTable: React.FC<CustomerVolumeAndProfitMonthlyReportTableProps> = ({
  data,
  profitType,
  handleClose,
  open,
  selectedYear,
  selectedMonth,
}) => {
  const currentMonth = getCurrentMonth(selectedYear, selectedMonth);
  const allMonths = getAllMonthNames(currentMonth);
  const excelFileName = selectedMonth
    ? `Customer-Volume-And-${profitType}-Profit-Monthly-Report_${allMonths[selectedMonth - 1]}-${selectedYear.toString()}`
    : `Customer-Volume-And-${profitType}-Profit-Monthly-Report_${selectedYear.toString()}`;
  const [transformedData, setTransformedData] = useState<{ 'Customer Name': string; [key: string]: string | number }[]>(
    [],
  );

  useEffect(() => {
    const s_transformedData = data.map(item => {
      const volumeAndProfitColumns: { [key: string]: string } = {};

      allMonths.map((month, index) => {
        volumeAndProfitColumns[`${month} (Volume)`] = `${item.monthlyVolumesProfits[index].volume}`;
        volumeAndProfitColumns[`${month} (Profit)`] = `£${item.monthlyVolumesProfits[index].profit}`;
      });

      return {
        'Customer Name': item.customerName,
        ...volumeAndProfitColumns,
        'Total Volume': item.totalVolume,
        'Total Profit': `£${item.totalProfit}`,
      };
    });

    setTransformedData(s_transformedData);
  }, [data]);

  const [totalVolumes, totalProfits] = allMonths
    .map(month => {
      return data.reduce(
        ([volumeTotal, profitTotal], customer) => {
          const monthData = customer.monthlyVolumesProfits.find(m => m.month === month);
          return [volumeTotal + (monthData?.volume || 0), profitTotal + (monthData?.profit || 0)];
        },
        [0, 0],
      );
    })
    .reduce(
      ([volumes, profits], [volumeTotal, profitTotal]) => {
        volumes.push(volumeTotal);
        profits.push(profitTotal);
        return [volumes, profits];
      },
      [[], []] as [number[], number[]],
    );

  const transformedTotalVolumesAndTotalProfits: { [key: string]: string } = {};
  allMonths.forEach((month, index) => {
    transformedTotalVolumesAndTotalProfits[`${month} (Volume)`] = `${totalVolumes[index]}`;
    transformedTotalVolumesAndTotalProfits[`${month} (Profit)`] = `£${totalProfits[index]}`;
  });

  const transformedDataWithTotalVolumesAndTotalProfits = [
    ...transformedData,
    {
      'Customer Name': 'YTD',
      ...transformedTotalVolumesAndTotalProfits,
      'Total Volume': data.reduce((total, customer) => total + customer.totalVolume, 0),
      'Total Profit': `£${data.reduce((total, customer) => total + customer.totalProfit, 0)}`,
    },
  ];

  return (
    <Box>
      <Dialog open={open} onClose={() => handleClose()} fullWidth fullScreen maxWidth='xl'>
        <DialogTitle>
          <Box display={'flex'} alignItems={'center'} gap={1}>
            {profitType} Monthly Gross Profit Report
            <Tooltip title='Download as Excel' placement='top'>
              <IconButton
                onClick={() => handleExcelDownload(transformedDataWithTotalVolumesAndTotalProfits, excelFileName)}
              >
                <DownloadOutlined />
              </IconButton>
            </Tooltip>
          </Box>
        </DialogTitle>
        <DialogContent>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align='center' sx={{ borderRight: '1px solid #515151' }}>
                    Customer Name
                  </TableCell>
                  {allMonths.map(month => (
                    <React.Fragment key={month}>
                      <TableCell align='center'>{month} (Volume)</TableCell>
                      <TableCell align='center' sx={{ borderRight: '1px solid #515151' }}>
                        {month} (Profit)
                      </TableCell>
                    </React.Fragment>
                  ))}
                  <TableCell align='center'>Total Volume</TableCell>
                  <TableCell align='center'>Total Profit</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((customer, index) => (
                  <TableRow key={index} sx={{ backgroundColor: '#292929' }}>
                    <TableCell align='left' sx={{ borderRight: '1px solid #515151' }}>
                      <Tooltip title={customer.customerName} placement='top'>
                        <span>{truncateName(customer.customerName)}</span>
                      </Tooltip>
                    </TableCell>
                    {allMonths.map(month => {
                      const monthData = customer.monthlyVolumesProfits.find(m => m.month === month);
                      return (
                        <React.Fragment key={`${month}-${customer.customerId}`}>
                          <TableCell align='center'>{monthData?.volume || 0}</TableCell>
                          <TableCell align='center' sx={{ borderRight: '1px solid #515151' }}>
                            £{monthData?.profit || 0}
                          </TableCell>
                        </React.Fragment>
                      );
                    })}
                    <TableCell align='center'>{customer.totalVolume}</TableCell>
                    <TableCell align='center'>£{customer.totalProfit}</TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell sx={{ borderRight: '1px solid #515151' }}>YTD</TableCell>
                  {totalVolumes.map((totalVolume, index) => (
                    <React.Fragment key={index}>
                      <TableCell align='center'>{totalVolume}</TableCell>
                      <TableCell align='center' sx={{ borderRight: '1px solid #515151' }}>
                        £{totalProfits[index]}
                      </TableCell>
                    </React.Fragment>
                  ))}
                  <TableCell align='center'>
                    {data.reduce((total, customer) => total + customer.totalVolume, 0)}
                  </TableCell>
                  <TableCell align='center'>
                    £{data.reduce((total, customer) => total + customer.totalProfit, 0)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <GeneralDialogueActions noSubmission handleClose={handleClose} />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default CustomerVolumeAndProfitMonthlyReportTable;
