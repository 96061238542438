import TextField from '@mui/material/TextField';
import { Invoice } from 'models/booking.model';
import React, { useEffect } from 'react';
import { Controller, UseFormReturn, useWatch } from 'react-hook-form';
import { useGetExchangeRateQuery } from 'state/api';

interface ExRateProp {
  index: number;
  defaultCurrency: string;
  form: UseFormReturn<Invoice, any, Invoice>;
}

export const ExRateIsolatedReRender: React.FC<ExRateProp> = ({ index, defaultCurrency, form }) => {
  const sourceCurrency = useWatch({
    control: form.control,
    name: `charges.${index}.currency`,
    defaultValue: form.getValues(`charges.${index}.currency`) || defaultCurrency,
  });
  const invoiceCurrency = useWatch({
    control: form.control,
    name: 'invoiceCurrency',
    defaultValue: form.getValues('invoiceCurrency') || defaultCurrency,
  });

  // since the api calls are cached this would fire only for new combinations
  const exchangeRate = useGetExchangeRateQuery([sourceCurrency, invoiceCurrency]);
  useEffect(() => {
    const initialValue = form.getValues(`charges.${index}.exchangeRate`);
    // const isCurrencyChanged =
    //   form.getFieldState(`charges.${index}.currency`).isDirty || form.getFieldState('bankAccount').isDirty;
    form.setValue(`charges.${index}.exchangeRate`, exchangeRate.isFetching ? initialValue : exchangeRate.data);
  }, [exchangeRate.data, exchangeRate.isFetching, form, index]);

  return (
    <Controller
      name={`charges.${index}.exchangeRate`}
      control={form.control}
      defaultValue={1}
      rules={{ required: 'Exchange Rate is required' }}
      render={({ field }) => {
        return (
          <TextField
            autoComplete='off'
            type='number'
            label='Ex. Rate *'
            error={!!form.formState.errors?.charges?.[index]?.exchangeRate}
            helperText={form.formState.errors?.charges?.[index]?.exchangeRate?.message}
            {...field}
          />
        );
      }}
    />
  );
};
