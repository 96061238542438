import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Label } from 'recharts';
import { useTheme } from '@mui/material';

interface CustomLineChartProps {
  data: any;
  key1?: string;
  key2?: string;
}

const CustomLineChart: React.FC<CustomLineChartProps> = ({ data, key1, key2 }) => {
  const theme = useTheme();
  return (
    <ResponsiveContainer width='100%' height='100%'>
      <LineChart width={500} height={400} data={data} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
        <CartesianGrid vertical={false} stroke={theme.palette.grey[800]} />
        <XAxis dataKey='month' tickLine={false} style={{ fontSize: '10px' }} />
        <YAxis tickLine={false} axisLine={false} style={{ fontSize: '10px' }}>
          <Label value='£' position='insideLeft' style={{ textAnchor: 'middle' }} />
        </YAxis>
        <Tooltip
          cursor={false}
          contentStyle={{ backgroundColor: theme.palette.background.alt }}
          labelStyle={{ color: theme.palette.text.primary }}
          itemStyle={{ color: theme.palette.text.primary }}
        />
        <Legend
          wrapperStyle={{
            fontSize: '10px',
          }}
        />
        <Line type='monotone' dataKey={key1} stroke={theme.palette.primary.main} />
        {key2 && <Line type='monotone' dataKey={key2} stroke={theme.palette.secondary.light} />}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default CustomLineChart;
