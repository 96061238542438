import * as React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import { AppState, RedirectLoginOptions, useAuth0, User } from '@auth0/auth0-react';
import LoginButton from 'components/LoginButton';
import { useNavigate } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';

function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: stringToColor(name),
      width: 40,
      height: 40,
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  };
}

interface AccountMenuProps {
  user: User | undefined;
}

const AccountMenu: React.FC<AccountMenuProps> = ({ user }) => {
  const navigate = useNavigate();
  const { logout } = useAuth0();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (typeof user === 'undefined') {
    return (
      <Box sx={{ padding: '1rem', textAlign: 'center' }}>
        <LoginButton />
      </Box>
    );
  }

  return (
    <React.Fragment>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          padding: '1rem',
          borderRadius: '8px',
          backgroundColor: '#1f1f21',
          cursor: 'pointer',
        }}
        onClick={handleClick}
      >
        <Avatar
          alt={user.nickname}
          src={user.picture}
          sx={{ width: 40, height: 40, objectFit: 'cover' }}
          {...(user?.picture ? {} : { ...stringAvatar(user.name ?? user.nickname ?? 'Profile') })}
        />
        <Tooltip title={user.email}>
          <Box sx={{ display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
            <Typography
              variant='subtitle1'
              sx={{
                fontWeight: 'bold',
                wordBreak: 'break-word',
                whiteSpace: 'normal',
              }}
            >
              {user.name ?? user.nickname}
            </Typography>
            <Typography
              variant='body2'
              sx={{
                color: '#888',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'normal',
              }}
            >
              {user.email}
            </Typography>
          </Box>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id='account-menu'
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 3,
          sx: {
            mt: 1.5,
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={() => navigate('/settings')}>
          <ListItemIcon>
            <Settings fontSize='small' />
          </ListItemIcon>
          Settings
        </MenuItem>
        <MenuItem
          onClick={() =>
            logout({
              returnTo: window.location.origin,
            } as RedirectLoginOptions<AppState>)
          }
        >
          <ListItemIcon>
            <Logout fontSize='small' />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};

export default AccountMenu;
