import Box from '@mui/material/Box';
import Header from 'components/Header';
import HorizontalTabs from 'components/HorizontalTabs';
import InvoicesComponent from 'components/InvoicesComponent';
import ReceiptsAndPayments from 'components/ReceiptsAndPayment';
import React from 'react';
import AdvancePayments from 'scenes/advancePayments';
import BankAccounts from 'scenes/bankAccounts';

const AccountTabs = [
  {
    label: 'Sales Invoice',
    value: <InvoicesComponent type='salesInvoices' />,
  },
  {
    label: 'Credit Note',
    value: <InvoicesComponent type='creditNotes' />,
  },
  {
    label: 'Purchase Invoice',
    value: <InvoicesComponent type='purchaseInvoices' />,
  },
  {
    label: 'Debit Note',
    value: <InvoicesComponent type='debitNotes' />,
  },
  {
    label: 'Receipts',
    value: <ReceiptsAndPayments type={'Receipt'} />,
  },
  {
    label: 'Payments',
    value: <ReceiptsAndPayments type={'Payment'} />,
  },
  {
    label: 'Advances',
    value: <AdvancePayments type={'Advance'} />,
  },
];

const Accounts = () => {
  return (
    <Box m='1rem 2.5rem'>
      {/* <Header title='Accounts' /> */}
      <HorizontalTabs tabs={AccountTabs} />
    </Box>
  );
};

export default Accounts;
