import dayjs from 'dayjs';
import { Booking, TeamType, TransportSchedule, InvoiceCharge, Invoice } from 'models/booking.model';
import { Site, Customer, Port, Vendor, IAdvPaymentsResponse, IBankAccounts } from 'models/index.model';
import { exportBookingTeams, importBookingTeams } from 'dataAssets/constants';
import { DEFAULT_BOOKING } from 'dataAssets/constants';
import { useSelector } from 'react-redux';
import { sec } from 'auth/accessToken';
import axios from 'axios';
import { useEffect, useMemo, useRef } from 'react';
import { AlertColor } from '@mui/material/Alert';
import { Hbl } from 'models/hbl.model';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

const EMPTY_VALUE_LABEL = { value: '', label: '' };

export interface SnackbarState {
  open: boolean;
  color: AlertColor;
  message: string;
}

export const defaultSnackbarState: SnackbarState = {
  open: false,
  color: 'success',
  message: '',
};

export function getValueAndLabelFromPort(port?: Port) {
  return port
    ? {
        value: `${port._id}`,
        label: `${port.portName}, ${port.country}`,
      }
    : EMPTY_VALUE_LABEL;
}

export function getValueAndLabelFromBooking(booking?: Booking) {
  return booking
    ? {
        value: `${booking._id}`,
        label: `${booking.bookingNumber}`,
      }
    : EMPTY_VALUE_LABEL;
}

export function getValueAndLabelFromVendor(vendor?: Vendor) {
  return vendor
    ? {
        value: `${vendor._id}`,
        label: `${vendor.name}`,
      }
    : EMPTY_VALUE_LABEL;
}

export function getValueAndLabelFromSite(site?: Site) {
  return site
    ? {
        value: site._id,
        label: `${site.siteName}, ${site.address}, ${site.city}, ${site.postcode}, ${site.country}`,
      }
    : EMPTY_VALUE_LABEL;
}

export function getValueAndLabelFromCustomer(customer?: Customer) {
  return customer
    ? {
        value: `${customer._id}`,
        label: `${customer.name}`,
      }
    : EMPTY_VALUE_LABEL;
}

export function getValueAndLabelFromInvoices(invoice?: Invoice) {
  return invoice
    ? {
        value: `${invoice._id}`,
        label: `${invoice.approvedInvoiceNumber}`,
      }
    : EMPTY_VALUE_LABEL;
}

export function getValueAndLabelFromAdvPayments(advPayment?: IAdvPaymentsResponse) {
  return advPayment
    ? {
        value: `${advPayment._id}`,
        label: `${advPayment.advPaymentNumber}`,
      }
    : EMPTY_VALUE_LABEL;
}

export function getValueAndLabelFromBankAccounts(account?: IBankAccounts) {
  return account
    ? {
        value: `${account._id}`,
        label: `${account.bank} - ${account.currency} - ${account.accountNumber}`,
      }
    : EMPTY_VALUE_LABEL;
}

export function handleSnackbarClose(reason: string, setStateFunc: React.Dispatch<React.SetStateAction<boolean>>) {
  if (reason === 'clickaway') {
    return;
  }

  setStateFunc(false);
}

export function getLabelForBookingDetail(value: any, bookingDetail: keyof Booking) {
  switch (bookingDetail) {
    case 'consignor':
    case 'consignee':
    case 'carrier':
      return value?.name;
    case 'agent':
      return value?.name;
    case 'portOfLoading':
    case 'portOfDestination':
    case 'placeOfDelivery':
      return `${value?.portName ?? 'N/A'}, ${value?.country ?? 'N/A'}`;
    case 'cargoValue':
      return `${value?.currency} ${value?.value}`;
    case 'vesselVoyage':
      return `${value?.vesselName}, ${value?.voyageNumber} [${value?.vesselFlag}]`;
    case 'etaPOL':
    case 'etd':
    case 'eta':
      return dayjs(value).format('DD/MM/YYYY');
    default:
      return value;
  }
}

export const useUserPermissions = () => {
  return useSelector((state: any) => state.global.permissions);
};

export const formatDeadline = (deadline: string) => {
  return deadline !== 'N/A' ? dayjs(deadline).format('DD/MM/YYYY') : 'TBA';
};

export const setBookingTeams = (hasExportPermission: boolean, hasImportPermission: boolean) => {
  if (hasExportPermission && hasImportPermission) {
    return Array.from(new Set(exportBookingTeams.concat(importBookingTeams))) as TeamType[];
  } else if (hasExportPermission) {
    return exportBookingTeams;
  } else if (hasImportPermission) {
    return importBookingTeams;
  }
};

export const containerNumbersList = (transportSchedule: TransportSchedule[] | undefined) => {
  return transportSchedule
    ? transportSchedule.map((schedule: TransportSchedule) => schedule.containerNumber).join('/ ')
    : '';
};

export const totalInvoiceValue = (charges: InvoiceCharge[], numberOfContainer: number) => {
  const total = charges?.reduce(
    (acc, charge) => acc + charge.rate * charge.exchangeRate * (charge.base === 'CN' ? numberOfContainer : 1),
    0,
  );
  return total?.toFixed(2);
};

export const setDefaultBookingValues = (hasExportPermission: boolean, hasImportPermission: boolean): Booking => {
  return {
    ...DEFAULT_BOOKING,
    bookingType: hasExportPermission ? 'Export' : hasImportPermission ? 'Import' : 'Export',
    bookingTeam: hasExportPermission ? TeamType.Rockers : hasImportPermission ? TeamType.Falcons : TeamType.Rockers,
  };
};

export const checkMissingKeys = (booking: Booking) => {
  const missingKeys: string[] = [];

  ['consignor', 'consignee', 'carrierBookingNumber'].forEach(key => {
    if (!booking[key as keyof Booking]) {
      missingKeys.push(key);
    }
  });

  if (!booking.transportSchedule?.length) {
    missingKeys.push('Transport Schedule');
  }

  // Check if any schedule within transportSchedule is missing containerNumber
  const isContainerMissing = booking.transportSchedule?.some(schedule => !schedule.containerNumber);
  if (isContainerMissing) {
    missingKeys.push('Container Numbers');
  }

  return missingKeys;
};

export const checkHblMissingKeys = (hblFormData: Hbl) => {
  const missingKeys: string[] = [];

  const fieldChecks = [
    { key: 'shipper', label: 'shipper' },
    { key: 'consignee', label: 'consignee' },
    { key: 'notifyParty', label: 'notifyParty' },
    { key: 'deliveryContactDetails', label: 'deliveryContactDetails' },
    { key: 'placeOfReceipt', label: 'placeOfReceipt' },
    { key: 'portOfLoading', label: 'portOfLoading' },
    { key: 'portOfDischarge', label: 'portOfDischarge' },
    { key: 'placeOfDelivery', label: 'placeOfDelivery' },
    { key: 'numberOfOriginalBls', label: 'numberOfOriginalBls' },
    { key: 'payableAt', label: 'payableAt' },
    { key: 'blType', label: 'blType' },
    { key: 'blNumber', label: 'blNumber' },
    { key: 'placeAndDateOfIssue.place', label: 'Place Of Issue' },
    { key: 'placeAndDateOfIssue.date', label: 'Date Of Issue' },
    { key: 'oceanVessel.vesselFlag', label: 'Ocean Vessel Flag' },
    { key: 'oceanVessel.vesselName', label: 'Ocean Vessel Name' },
    { key: 'oceanVessel.voyageNumber', label: 'Ocean Vessel Number' },
  ];

  fieldChecks.forEach(({ key, label }) => {
    const value = key.split('.').reduce((obj, segment) => (obj as any)?.[segment], hblFormData);
    if (!value) {
      missingKeys.push(label);
    }
  });

  if (!hblFormData.cargoDetails?.length) {
    missingKeys.push('Cargo Details');
  } else {
    const cargoChecks = [
      { key: 'marks', label: 'Cargo Marks' },
      { key: 'numbers', label: 'Cargo Numbers' },
      { key: 'numberOfPackages', label: 'Cargo Number Of Packages' },
      { key: 'kindOfPackages', label: 'Cargo Kind Of Packages' },
      { key: 'descriptionOfGoodsAndPackages', label: 'Cargo Description Of Goods And Packages' },
      { key: 'grossWeightCargo', label: 'Cargo Gross Weight' },
    ];

    cargoChecks.forEach(({ key, label }) => {
      const isMissing = hblFormData.cargoDetails?.some(detail => !detail[key as keyof typeof detail]);
      if (isMissing) {
        missingKeys.push(label);
      }
    });
  }

  return missingKeys;
};

export const splitTransportScheduleByHauler = (schedule?: TransportSchedule[]): Record<string, TransportSchedule[]> => {
  const haulerGroups: Record<string, TransportSchedule[]> = {};

  schedule?.forEach(item => {
    const hauler = item.hauler;

    if (!haulerGroups[hauler]) {
      haulerGroups[hauler] = [];
    }

    haulerGroups[hauler].push(item);
  });

  return haulerGroups;
};

export const uniqueNameRefinement = (items: any, itemToUpdate: any, key: string) => (name: any) => {
  const lowercaseName = name.trim().toLowerCase().replace(/\s+/g, ' ');
  return !items?.find(
    (item: any) =>
      item[key]?.trim().toLowerCase().replace(/\s+/g, ' ') === lowercaseName && item._id !== itemToUpdate?._id,
  );
};

export const setDialogueState = (
  open: boolean,
  setStateFunc: React.Dispatch<React.SetStateAction<boolean>>,
  refetchFunc?: () => void,
) => {
  setStateFunc(open);
  if (refetchFunc) {
    refetchFunc();
  }
};

export const extractDeadlineTime = (deadline: string | undefined) => {
  return deadline ? dayjs(deadline).format('HH:mm') : 'N/A';
};

export const get = (obj: Record<string, any>, path: string, defaultValue: unknown = undefined) => {
  const travel = (regexp: RegExp) =>
    String.prototype.split
      .call(path, regexp)
      .filter(Boolean)
      .reduce((res, key) => (res !== null && res !== undefined ? res[key] : res), obj);
  const result = travel(/[,[\]]+?/) || travel(/[,[\].]+?/);
  return result === undefined || result === obj ? defaultValue : result;
};

export const generateToken = async (id: string, code?: string) => {
  const details: Record<string, string> = {
    state: id,
  };
  if (typeof code !== 'undefined') {
    details.code = code;
  }
  const apiUrl = `${process.env.REACT_APP_BASE_URL}/api/v1/token/`;
  const accessToken = await sec.getAccessTokenSilently()();
  return axios.post(apiUrl, new URLSearchParams(details), {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const useInterval = (callback: () => void, delay: number) => {
  const savedCallback = useRef<typeof callback>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      if (savedCallback.current) {
        savedCallback.current();
      }
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};

export const extractInvoiceType = (invoiceNumber: string) => {
  const parts = invoiceNumber.split('/');
  return parts[1];
};

export const extractInvoiceShortForm = (invoiceType: string) => {
  switch (invoiceType) {
    case 'salesInvoices':
      return 'SI';
    case 'purchaseInvoices':
      return 'PI';
    case 'creditNotes':
      return 'CN';
    case 'debitNotes':
      return 'DN';
    default:
      return '';
  }
};

export const getCurrentMonth = (selectedYear: number, selectedMonth: number | null) => {
  let currentMonth = dayjs().month();

  if (selectedYear.toString() === dayjs().year().toString()) {
    if (selectedMonth === dayjs().month() || selectedMonth === null) {
      currentMonth = dayjs().month();
    } else if (selectedMonth) {
      currentMonth = selectedMonth - 1;
    }
  } else {
    if (!selectedMonth) {
      currentMonth = 11; // Set the month to December (11) when the selected year is not the current year to show data for the whole year
    } else if (selectedMonth) {
      currentMonth = selectedMonth - 1;
    }
  }

  return currentMonth;
};

export const getAllMonthNames = (currentMonth: number) => {
  const allMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'].slice(
    0,
    currentMonth + 1,
  );

  return allMonths;
};

export const truncateName = (name: string) => {
  const MAX_NAME_LENGTH = 15;
  return name.length > MAX_NAME_LENGTH ? `${name.slice(0, MAX_NAME_LENGTH)}...` : name;
};

export function formatpercentageChange(percentageChange: string) {
  return parseFloat(Number(percentageChange).toFixed(2));
}

export const getMonthNamesFromYear = (year: number) => {
  const months = useMemo(() => {
    const currentDate = dayjs();
    const isCurrentYear = year === currentDate.year();
    const monthNames = Array.from({ length: 12 }, (_, index) => ({
      value: index + 1,
      label: dayjs().month(index).format('MMMM'),
    }));

    return isCurrentYear ? monthNames.slice(0, currentDate.month() + 1) : monthNames;
  }, [year]);

  return months;
};

export const handleExcelDownload = (data: any[], fileName: string) => {
  const worksheet = XLSX.utils.json_to_sheet(data);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

  const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  const file = new Blob([excelBuffer], { type: 'application/octet-stream' });
  saveAs(file, `${fileName}.xlsx`);
};
