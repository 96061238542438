import { Customer, Port, Site, Vendor } from './index.model';
import { TransportSchedule, Charge, VesselVoyage } from './booking.model';

export interface Hbl {
  _id?: string;
  blNumber: string;
  blType: BLType | undefined;
  shipper: Customer | undefined;
  consignee: Customer | undefined;
  notifyParty: Customer | undefined;
  preCarriageBy: string;
  placeOfReceipt: Site | undefined;
  oceanVessel: VesselVoyage;
  portOfLoading: Port | undefined;
  portOfDischarge: Port | undefined;
  placeOfDelivery: Port | undefined;
  deliveryContactDetails: Vendor | undefined;
  cargoDetails: CargoDetails[] | undefined;
  sellRates?: HblSellRates[] | undefined;
  additionalDescription?: string;
  payableAt: string;
  numberOfOriginalBls: string;
  placeAndDateOfIssue: PlaceAndDateOfIssue;
  hblApproval: boolean;
}

export interface CargoDetails {
  marks: TransportSchedule['containerNumber'];
  numbers: TransportSchedule['sealNumber'];
  numberOfPackages: number | '';
  kindOfPackages: string;
  descriptionOfGoodsAndPackages: string;
  grossWeightCargo: TransportSchedule['weight'];
  tareWeight?: number | '';
  volume?: number | '';
}

interface PlaceAndDateOfIssue {
  place: string;
  date: string;
}

export enum BLType {
  Original = 'Original',
}

export interface HblSellRates extends Omit<Charge, 'additionalDetails'> {
  exchangeRate?: string;
  paymentType: PaymentType;
}

export enum PaymentType {
  Prepaid = 'Prepaid',
  Collect = 'Collect',
}
