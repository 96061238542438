import React from 'react';
import { Box, Typography } from '@mui/material';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';

interface RenderChangeBoxProps {
  selectedMonth: number | null;
  changePercentage: string | undefined;
  formatChange: (percentageChange: string) => number;
}

const RenderChangeBox: React.FC<RenderChangeBoxProps> = ({ selectedMonth, changePercentage, formatChange }) => {
  if (!selectedMonth || changePercentage === undefined || Number(changePercentage) === 0) return null;

  const isPositive = Number(changePercentage) > 0;
  const backgroundColor = isPositive ? 'green' : 'red';
  const ArrowIcon = isPositive ? KeyboardArrowUp : KeyboardArrowDown;

  return (
    <Box
      display='flex'
      alignItems='center'
      color='white'
      paddingX='.3rem'
      borderRadius='4px'
      sx={{ backgroundColor }}
      fontSize='12px'
    >
      <Typography fontSize='12px' fontWeight='bold'>
        {formatChange(changePercentage)}%
      </Typography>
      <ArrowIcon fontSize='small' />
    </Box>
  );
};

export default RenderChangeBox;
