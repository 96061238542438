import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogContent,
  Box,
  Tooltip,
  DialogTitle,
  IconButton,
} from '@mui/material';
import { MonthlyPortCombinationVolumes } from 'models/analytics';
import { BaseDialogueProps } from 'models/index.model';
import GeneralDialogueActions from 'components/GeneralDialogueActions';
import { getAllMonthNames, getCurrentMonth, handleExcelDownload, truncateName } from 'utils/utils';
import { DownloadOutlined } from '@mui/icons-material';

interface MonthlyPortCombinationVolumesTableProps extends BaseDialogueProps {
  data: MonthlyPortCombinationVolumes[];
  bookingType: string;
  selectedYear: number;
  selectedMonth: number | null;
}

const MonthlyPortCombinationVolumesTable: React.FC<MonthlyPortCombinationVolumesTableProps> = ({
  data,
  bookingType,
  handleClose,
  open,
  selectedYear,
  selectedMonth,
}) => {
  const currentMonth = getCurrentMonth(selectedYear, selectedMonth);
  const allMonths = getAllMonthNames(currentMonth);
  const excelFileName = selectedMonth
    ? `Port-Combination-Volumes-Monthly-${bookingType}-Report_${allMonths[selectedMonth - 1]}-${selectedYear.toString()}`
    : `Port-Combination-Volumes-Monthly-${bookingType}-Report_${selectedYear.toString()}`;
  const [transformedData, setTransformedData] = useState<{ 'Trade Route': string; [key: string]: string | number }[]>(
    [],
  );

  useEffect(() => {
    const s_transformedData = data.map(item => {
      const portCombinationVolumeColumns: { [key: string]: string } = {};

      allMonths.map((month, index) => {
        portCombinationVolumeColumns[`${month} (Volume)`] = `${item.monthlyPortCombinations[index].volume}`;
      });

      return {
        'Trade Route': item.combination,
        ...portCombinationVolumeColumns,
        'Total Volume': item.totalVolume,
      };
    });

    setTransformedData(s_transformedData);
  }, [data]);

  const totalVolumes = allMonths.map(month => {
    return data.reduce((total, portCombination) => {
      const monthData = portCombination.monthlyPortCombinations.find(m => m.month === month);
      return total + (monthData?.volume || 0);
    }, 0);
  });

  const transformedTotalVolumes: { [key: string]: string } = {};
  allMonths.forEach((month, index) => {
    transformedTotalVolumes[`${month} (Volume)`] = `${totalVolumes[index]}`;
  });

  const transformedDataWithTotalVolumes = [
    ...transformedData,
    {
      'Trade Route': 'YTD',
      ...transformedTotalVolumes,
      'Total Volume': data.reduce((total, portCombination) => total + portCombination.totalVolume, 0),
    },
  ];

  return (
    <Box>
      <Dialog open={open} onClose={() => handleClose()} fullWidth fullScreen maxWidth='xl'>
        <DialogTitle>
          <Box display={'flex'} alignItems={'center'} gap={1}>
            {bookingType} Monthly Port Combination Volume Report
            <Tooltip title='Download as Excel' placement='top'>
              <IconButton onClick={() => handleExcelDownload(transformedDataWithTotalVolumes, excelFileName)}>
                <DownloadOutlined />
              </IconButton>
            </Tooltip>
          </Box>
        </DialogTitle>
        <DialogContent>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align='center' sx={{ borderRight: '1px solid #515151' }}>
                    Trade Route
                  </TableCell>
                  {allMonths.map(month => (
                    <React.Fragment key={month}>
                      <TableCell align='center' sx={{ borderRight: '1px solid #515151' }}>
                        {month} (Volume)
                      </TableCell>
                    </React.Fragment>
                  ))}
                  <TableCell align='center'>Total Volume</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((portCombination, index) => (
                  <TableRow key={index} sx={{ backgroundColor: '#292929' }}>
                    <TableCell align='left' sx={{ borderRight: '1px solid #515151' }}>
                      <Tooltip title={portCombination.combination} placement='top'>
                        <span>{truncateName(portCombination.combination)}</span>
                      </Tooltip>
                    </TableCell>
                    {allMonths.map(month => {
                      const monthData = portCombination.monthlyPortCombinations.find(m => m.month === month);
                      return (
                        <React.Fragment key={`${month}-${portCombination.combination}`}>
                          <TableCell align='center' sx={{ borderRight: '1px solid #515151' }}>
                            {monthData?.volume || 0}
                          </TableCell>
                        </React.Fragment>
                      );
                    })}
                    <TableCell align='center'>{portCombination.totalVolume}</TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell sx={{ borderRight: '1px solid #515151' }}>YTD</TableCell>
                  {totalVolumes.map((totalVolume, index) => (
                    <React.Fragment key={index}>
                      <TableCell align='center' sx={{ borderRight: '1px solid #515151' }}>
                        {totalVolume}
                      </TableCell>
                    </React.Fragment>
                  ))}
                  <TableCell align='center'>
                    {data.reduce((total, portCombination) => total + portCombination.totalVolume, 0)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <GeneralDialogueActions noSubmission handleClose={handleClose} />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default MonthlyPortCombinationVolumesTable;
